export default [
  {
    name: `pepiniere1`,
    video: require(`@/assets/dialogs/videos/pepiniere_hotspot_1.mp4`),
  },
  {
    name: `pepiniere2`,
    img: require(`@/assets/dialogs/images/pepiniere_hotspot2.webp`),
  },
  {
    name: `pepiniere3`,
    video: require(`@/assets/dialogs/videos/pepiniere_hotspot_3.mp4`),
  },
  {
    name: `pepiniere4`,
    img: require(`@/assets/dialogs/images/pepiniere_hotspot4.webp`),
  },
  {
    name: `pepiniere5`,
    video: require(`@/assets/dialogs/videos/pepiniere_hotspot_5.mp4`),
  },
  {
    name: `prod_dialog_1`,
    img: require(`@/assets/dialogs/images/prod_hotspot_1.webp`),
  },
  {
    name: `prod_dialog_2`,
    img: require(`@/assets/dialogs/images/prod_hotspot_2.webp`),
  },
  {
    name: `prod_dialog_3`,
    img: require(`@/assets/dialogs/images/prod_hotspot_3.webp`),
  },
  {
    name: `prod_dialog_4`,
    img: require(`@/assets/dialogs/images/prod_hotspot_4.webp`),
  },
  {
    name: `prod_dialog_5`,
    video: require(`@/assets/dialogs/videos/prod_hotspot5.mp4`),
  },
  {
    name: `prod_dialog_6`,
    img: require(`@/assets/dialogs/images/prod_hotspot_6.webp`),
  },
  {
    name: `prod_dialog_7`,
    img: require(`@/assets/dialogs/images/prod_hotspot_7.webp`),
  },
  {
    name: `prod_dialog_8`,
    img: require(`@/assets/dialogs/images/prod_hotspot_8.webp`),
  },
  {
    name: `water_dialog_1`,
    img: require(`@/assets/dialogs/images/water_hotspot_1.webp`),
  },
  {
    name: `water_dialog_2`,
    img: require(`@/assets/dialogs/images/water_hotspot_2.webp`),
  },
  {
    name: `water_dialog_3`,
    img: require(`@/assets/dialogs/images/water_hotspot_3.webp`),
  },
  {
    name: `showroom_dialog_1`,
    img: require(`@/assets/dialogs/images/showroom_hotspot_1.webp`),
  },
  {
    name: `showroom_dialog_2`,
    img: require(`@/assets/dialogs/images/showroom_hotspot_2.webp`),
  },
  {
    name: `showroom_dialog_3`,
    img: require(`@/assets/dialogs/images/showroom_hotspot_3.webp`),
  },
  {
    name: `showroom_dialog_4`,
    img: require(`@/assets/dialogs/images/showroom_hotspot_4.webp`),
  },
  {
    name: `showroom_dialog_5`,
    img: require(`@/assets/dialogs/images/showroom_hotspot_5.webp`),
  },
  {
    name: `showroom_dialog_6`,
    img: require(`@/assets/dialogs/images/showroom_hotspot_6.webp`),
  },
];
