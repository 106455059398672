<template>
  <div class="background" style="z-index: 9">
    <div
      class="reality-tutorial light-mode"
      :class="{
        visible,
        mobile: $breakpoints.isMobile,
      }"
    >
      <h2 class="tutorial-title">{{ config.title }}</h2>
      <div class="tutorial-elements-wrapper">
        <div
          :id="`tutorial-element-${idx}`"
          class="tutorial-element"
          :class="`${idx !== 0 ? `` : ` tutorial-element-visible`}`"
          v-for="(elem, idx) in config.tutorialElements"
          :key="`tuto-${elem.name}`"
        >
          <video
            class="element-video"
            :autoplay="true"
            :playsinline="true"
            :webkit-playsinline="true"
            :muted="true"
            :default-muted="true"
            :loop="true"
            v-if="renderVideos && !$breakpoints.isMobile && elem.video"
          >
            <source :src="elem.video" type="video/mp4" />
          </video>
          <div class="tutorial-gif-spacer" v-else>
            <img
              :src="
                $breakpoints.isMobile && elem.mobilegif
                  ? elem.mobilegif
                  : elem.gif
              "
              alt="tutorial-gif"
              class="element-video"
            />
          </div>
          <p class="element-text font-FoundersGrotesk">
            {{
              $breakpoints.isMobile && elem.mobiletext
                ? elem.mobiletext
                : elem.text
            }}
          </p>
        </div>
      </div>
      <a
        class="close-tutorial-cta font-FoundersGrotesk"
        @click.prevent="close"
        >{{ config.continueCTA }}</a
      >
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      visible: {
        type: Boolean,
        required: false,
        default: () => false,
      },
      config: {
        type: Object,
        required: true,
      },
    },

    data: () => ({
      renderVideos: true,
    }),

    mounted() {
      this.startAnimation();
    },

    methods: {
      close() {
        this.$emit(`close`);
      },
      startAnimation() {
        const tuto1 = document.getElementById(`tutorial-element-1`);
        const tuto2 = document.getElementById(`tutorial-element-2`);

        setTimeout(() => {
          tuto1.classList.add(`tutorial-element-visible`);
        }, 2000);
        setTimeout(() => {
          tuto2.classList.add(`tutorial-element-visible`);
        }, 4000);
      },
    },

    /* watch: {
      visible(newVal) {
        if (!newVal) {
          setTimeout(() => {
            this.renderVideos = false;
          }, 500);
        } else {
          const tuto1 = document.getElementById(`tutorial-element-1`);
          const tuto2 = document.getElementById(`tutorial-element-2`);

          setTimeout(() => {
            tuto1.classList.add(`tutorial-element-visible`);
          }, 2000);
          setTimeout(() => {
            tuto2.classList.add(`tutorial-element-visible`);
          }, 4000);
        }
      },
    },*/
  };
</script>

<style lang="scss" scoped>
  @use "../assets/css/theme";

  .reality-tutorial {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    min-width: 700px;
    padding: 3em 7em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    color: theme.$darkgreen;
    transition: opacity 0.5s ease-in-out;
    z-index: 11;
    &.visible {
      opacity: 1;
      pointer-events: all;
    }

    .tutorial-title {
      font-size: 1.5em;
      font-weight: 700;
    }

    .tutorial-elements-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-around;

      .tutorial-element {
        width: 33%;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        padding: 1em;
        position: relative;
        &:not(:last-child):after {
          content: "";
          height: 60%;
          width: 1px;
          position: absolute;
          right: 0;
          top: 20%;
          background-color: theme.$darkgreen;
        }
        &.tutorial-element-visible {
          opacity: 1;
        }

        .element-video {
          width: 100%;
        }

        .tutorial-gif-spacer {
          height: 115px;
          position: relative;
          .element-video {
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            left: 0;
            right: 0;
            width: auto;
            max-height: 100px;
          }
        }

        .element-text {
          font-weight: 200;
          margin: 3em 0;
          font-size: 15px;
        }
      }
    }

    .close-tutorial-cta {
      margin: 4em 0 2em;
      padding: 1.25em 2.5em;
      border-radius: 6em;
      border: 1px solid theme.$darkgreen;
      color: theme.$darkgreen;
      cursor: pointer;
      font-weight: 200;
      transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

      &:hover {
        background-color: theme.$darkgreen;
        color: #ffffff;
      }
    }

    &.mobile {
      width: auto;
      min-width: auto;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transform: translate(0, 0);
      margin: 5em 2em 2em;
      padding: 0;

      .tutorial-elements-wrapper {
        flex-direction: column;
        flex-grow: 0;
        height: 80%;

        .tutorial-element {
          display: flex;
          align-items: center;
          width: 100%;
          height: 30%;
          flex-direction: column;
          padding: 1vw;

          &:not(:last-child):after {
            content: none;
          }
          .element-video {
            height: 22vw;
            object-fit: cover;
            object-position: center;
          }

          .element-text {
            margin: 0;
            padding: 0em 5em;
          }
          .tutorial-gif-spacer {
            width: 100%;
          }
        }
      }
      .close-tutorial-cta {
        padding: 3vw;
        margin: 2em 0 1em;
      }
    }
  }
</style>
