export default {
  name: `loading_screen`,
  backgroundvideo: require(`@/assets/videos/backgroundvideo.mp4`),
  images: {
    typo: require(`@/assets/icons/Plant_2.svg`),
    logo: {
      img: require(`@/assets/logos/logo_green.svg`),
      alt: "Ulé",
    },
  },
};
