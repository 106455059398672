export default [
  {
    name: `pepiniere1`,
    title: `Preparation of seedlings and cuttings`,
    sound: require(`@/assets/dialogs/sounds/en/nurserie_1.mp3`),
    content: [
      `This is the <b>first stage</b> of plant cultivation. Tulsi <b>seeds and</b> Coleus and Centella <b>cuttings are inserted into a sponge</b>.`,
      `The sponge makes it easier for the plant to take root and gives it the necessary moisture for its development.`,
    ],
  },
  {
    name: `pepiniere2`,
    title: `Cultivation using hydroponics`,
    sound: require(`@/assets/dialogs/sounds/en/nurserie_2.mp3`),
    content: [
      `The plants are not grown in soil. <b>Hydroponic pools</b> located on each floor are supplied with <b>water rich in minerals and nutrients</b> which irrigates the plants through their roots.`,
    ],
  },
  {
    name: `pepiniere3`,
    title: `Foggers`,
    sound: require(`@/assets/dialogs/sounds/en/nurserie_3.mp3`),
    content: [
      `Each floor has an <b>irigation system</b> that is automatically activated to maintain the <b>optimal humid environment</b> necessary for plant growth.`,
    ],
  },
  {
    name: `pepiniere4`,
    title: `LED lighting`,
    sound: require(`@/assets/dialogs/sounds/en/nurserie_4.mp3`),
    content: [
      `Each floor is equipped with LED lights, a lighting system that keeps <b>energy consumption to a minimum</b>.`,
      `The LEDs reproduce the <b>conditions of natural sunlight</b> that each plant needs and make it possible to grow plants all year round, whatever the season, and with consistent quality.`,
    ],
  },
  {
    name: `pepiniere5`,
    title: `Root development`,
    sound: require(`@/assets/dialogs/sounds/en/nurserie_5.mp3`),
    content: [
      `<b>Agronomists</b> check the <b>root growth daily</b>. It takes 2 to 3 weeks for the plants to develop sufficient roots to then be transferred to the vertical towers in the grow rooms, where they will grow to maturity.`,
    ],
  },
  {
    name: `prod_dialog_1`,
    title: `Transfer to the vertical towers`,
    sound: require(`@/assets/dialogs/sounds/en/prod_1.mp3`),
    content: [
      `The plants are <b>transferred by hand</b> from the nursery to the <b>vertical towers</b> to begin the second phase of their growth.`,
      `Each tower is 3m high and can hold up to 400 plants of the same type. The vertical farm is a sort of unique cocoon where <b>60 parameters</b> have been set (temperature, humidity, light, etc.) to enable the plants to grow to their full potential.`,
    ],
  },
  {
    name: `prod_dialog_2`,
    title: `Cultivation using aeroponics`,
    sound: require(`@/assets/dialogs/sounds/en/prod_2.mp3`),
    content: [
      `The plants are <b>not grown in soil</b>. Inside each vertical tower, <b>water rich in nutrients</b> is sprayed onto the plant roots.`,
      `Anything not absorbed by the roots goes back to the technical unit for treatment. This closed-loop system allows <b>95% of the water used to be recycled</b>.`,
    ],
  },
  {
    name: `prod_dialog_3`,
    title: `LED lighting`,
    sound: require(`@/assets/dialogs/sounds/en/prod_3.mp3`),
    content: [
      `As the plants are not in their natural environment, each tower is equipped with blue and red <b>LEDs that mimic sunlight</b> and reproduce the day/night cycle.`,
      `To ensure that all plants are exposed to the same amount of light during their growth, the towers rotate.`,
    ],
  },
  {
    name: `prod_dialog_4`,
    title: `Harvesting the plants`,
    sound: require(`@/assets/dialogs/sounds/en/prod_4.mp3`),
    content: [
      `The plants grow on the vertical towers for several weeks. Their growth is monitored daily by agronomists to determine the best time to begin the <b>harvest, which is done entirely by hand</b>.`,
    ],
  },
  {
    name: `prod_dialog_5`,
    title: `Harvesting cuttings`,
    sound: require(`@/assets/dialogs/sounds/en/prod_5.mp3`),
    content: [
      `Coleus and Centella <b>cuttings</b> are taken by hand and <b>go to the nursery</b> where they are inserted into a sponge to grow new plants.`,
    ],
  },
  {
    name: `prod_dialog_6`,
    title: `Coleus`,
    sound: require(`@/assets/dialogs/sounds/en/prod_6.mp3`),
    content: [
      `- Latin name : COLEUS FORSKOHLII`,
      `- Main action : <b>Protects</b>`,
      `- Results* : +51% cell growth, +35% cell protection`,
      ``,
      `<b>Coleus</b> has been selected by our experts as it is rich in <b>antioxidants</b> which protect the skin from oxidative stress and optimise the skin’s resilience. `,
      ``,
      `*Proteomic analysis`,
    ],
  },
  {
    name: `prod_dialog_7`,
    title: `Centella`,
    sound: require(`@/assets/dialogs/sounds/en/prod_7.mp3`),
    content: [
      `- Latin name: CENTELLA ASIATICA`,
      `- Main action: <b>Regenerates</b>`,
      `- Effectiveness results*: +28% cell renewal, +28% collagen `,
      ``,
      `<b>Centella</b> has been selected by our experts because it stimulates <b>collagen</b> synthesis and helps revitalise the skin’s surface.`,
      ``,
      ` *Proteomic analysis`,
    ],
  },
  {
    name: `prod_dialog_8`,
    title: `Tulsi`,
    sound: require(`@/assets/dialogs/sounds/en/prod_8.mp3`),
    content: [
      `- Latin name: OCIMUM TENUIFLORUM`,
      `- Main action: <b>Destresses</b>`,
      `- Effectiveness results*: +31% protection against oxidative stress, +35% strength of the skin’s barrier function`,
      ``,
      `<b>Tulsi</b> is an adaptogenic plant known for its ability to manage <b>oxidative stress</b> and strengthen the skin barrier. Its destressing properties optimise the resilience of the skin.`,
      ``,
      ` *Proteomic analysis`,
    ],
  },
  {
    name: `water_dialog_1`,
    title: `Preparation of the solution`,
    sound: require(`@/assets/dialogs/sounds/en/water_1.mp3`),
    content: [
      `Each room in the vertical farm has its own <b>water reserve</b> in the form of a tank. The agronomists establish the <b>right dosage of water and nutrients</b> for each tank to ensure optimal plant development. Once calibrated, the solution is transported to the plants.`,
    ],
  },
  {
    name: `water_dialog_2`,
    title: `Water recycling`,
    sound: require(`@/assets/dialogs/sounds/en/water_2.mp3`),
    content: [
      `Water that is not absorbed by the plants goes back to the tank to be filtered, enriched with nutrients and is then ready to be used again. This system allows <b>95% of the water used to be recycled</b>.`,
    ],
  },
  {
    name: `water_dialog_3`,
    title: `pH control`,
    sound: require(`@/assets/dialogs/sounds/en/water_3.mp3`),
    content: [
      `In addition to monitoring the water quality, the agronomists check the <b>pH of the solution</b> daily by taking samples directly from the tank.`,
    ],
  },
  {
    name: `showroom_dialog_1`,
    title: `Biome Essentials`,
    sound: require(`@/assets/dialogs/sounds/en/showroom_1.mp3`),
    content: [
      `Healthy skin starts with a good foundation. Discover our <b>Biome Essentials</b>, two simple ways to <b>balance</b> the skin’s microbiome, <b>strengthen</b> the skin barrier and <b>prepare</b> your skin for the next stage of your beauty routine.`,
    ],
    link: {
      link: "https://www.spacenk.com/uk/brands/u/ule?prefn1=category&prefv1=Cleanser%7CToner&utm_source=Ule&utm_medium=Brand%7CPartnership&utm_campaign=biome",
      text: "buy now",
    },
  },
  {
    name: `showroom_dialog_2`,
    title: `Active Serums`,
    sound: require(`@/assets/dialogs/sounds/en/showroom_2.mp3`),
    content: [
      `Our highly <b>concentrated serums</b> can be layered or used individually to target skin needs and lifestyles. Each serum is made with a unique combination of our Pure 3otany™ Blend to <b>improve skin resilience</b> and <b>restore essential skin functions</b>, and enriched with active ingredients that deliver next-level skin benefits. They are clinically proven to address the signs of aging while supporting the skin barrier—for supple, plump and smooth skin that feels strong and healthy, no matter your skin type or lifestyle.`,
    ],
    link: {
      link: "https://www.spacenk.com/uk/brands/u/ule?prefn1=category&prefv1=Treatment&utm_source=Ule&utm_medium=Brand%7CPartnership&utm_campaign=serums",
      text: "buy now",
    },
  },
  {
    name: `showroom_dialog_3`,
    title: `Fortifying Creams`,
    sound: require(`@/assets/dialogs/sounds/en/showroom_3.mp3`),
    content: [
      `As we age, the skin’s essential functions decline. Our <b>fortifying creams</b> are composed of the Pure Botany™ Blend and targeted active ingredients which improve <b>the natural resilience of your skin</b>. Hydrated, nourished and protected, your skin is better prepared to handle stress, feels healthier and looks more radiant than ever !`,
    ],
    link: {
      link: "https://www.spacenk.com/uk/brands/u/ule?prefn1=category&prefv1=Moisturiser&utm_source=Ule&utm_medium=Brand%7CPartnership&utm_campaign=creams",
      text: "buy now",
    },
  },
  {
    name: `showroom_dialog_4`,
    title: `Nutri Beauty`,
    sound: require(`@/assets/dialogs/sounds/en/showroom_4.mp3`),
    content: [
      `Beauty begins on the inside. Our <b>Nutri Beauty</b> range has been developed in collaboration with a <b>nutritionist</b>. Vitamins, superfoods and essential minerals have been selected to target common nutritional deficiencies, to <b>improve well-being</b>, strengthen immunity, and improve skin's appearance for healthy, glowing skin!`,
    ],
    link: {
      link: "https://www.spacenk.com/uk/skincare/treatment/face-oils/avoir-it-all-UK200038029.html",
      text: "buy now",
    },
  },
  {
    name: `showroom_dialog_6`,
    title: `Facial Treatments`,
    sound: require(`@/assets/dialogs/sounds/en/showroom_6.mp3`),
    content: [
      `We have developed <b>face and body treatments</b> in which ancestral massage techniques are combined with cutting-edge technology for an experience of absolute well-being, that make skin appear radiant and revived. Discover our treatments at the <b>TOO Chill spa</b>, located on the 17th floor of the TOO Hotel, with stunning views across Paris and the Seine.`,
    ],
    link: {
      link: "https://toohotel.com/spa/",
      text: "learn more",
    },
  },
];
