<template>
  <hotspot-ui
    :class="`reality-hotspot ${category}`"
    :spot-config="spotConfig"
    :action="action"
    v-on="{ [action.type]: execAction }"
  >
    <div class="reality-hotspot-inner">
      {{ category === `number` ? idx : `` }}
    </div>
    <img
      v-if="spotImg"
      class="spotImg"
      :src="spotImg"
      @mouseover="isHover = true"
      @mouseleave="isHover = false"
    />
    <template v-else>
      <div
        v-if="label"
        v-html="label"
        class="reality-hotspot-inner font-SaolText"
      ></div
    ></template>
  </hotspot-ui>
</template>

<script>
  import HotspotUi from "@/components/HotspotUi/HotspotUi";

  export default {
    components: {
      HotspotUi,
    },

    props: {
      spotConfig: {
        type: Object,
        required: true,
      },
      idx: {
        type: String,
        required: true,
      },
    },

    data: () => ({
      visited: false,
      isHover: false,
    }),

    computed: {
      category() {
        return this.spotConfig?.category || `basic`;
      },
      label() {
        return this.spotConfig?.label || null;
      },
      spotImg() {
        if (this.isHover) {
          return this.spotConfig?.img_hover || null;
        }
        return this.spotConfig?.img || null;
      },
      action() {
        return this.spotConfig?.action || { type: ``, args: {} };
      },
      backgroundColor() {
        return this.spotConfig?.backgroundColor || "#ffffff";
      },
    },

    mounted() {
      this.visited =
        localStorage.getItem(`${this.spotConfig.name}-visited`) === "true";
    },

    methods: {
      execAction() {
        if (this.visited === false) {
          localStorage.setItem(`${this.spotConfig.name}-visited`, true);
          this.visited =
            localStorage.getItem(`${this.spotConfig.name}-visited`) === "true";
        }
        this.$emit(`click`, { ...this.action, idx: this.idx });
      },
    },
  };
</script>

<style lang="scss">
  @use "../assets/css/theme";
  .spotImg {
    width: 150px;
    height: 150px;
    transition: all 0.3s ease-in-out;
    &:hover {
      width: 140px;
      height: 140px;
    }
  }
  .reality-hotspot {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    //background-color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
    /* &.link {
      width: 170px;
      height: 80px;
      background-color: rgba(theme.$grey, 0.8);
      border-radius: 0;
      border: 1px solid rgba(theme.$grey, 0.8);
      button {
        background-image: none;
        border-radius: 0;
        background-repeat: no-repeat;
        background-position: center 10px;
        height: 80px;
        &:hover {
          background-image: url("~@/assets/icons/arrow_animated.svg");
          border-radius: 0;
          background-repeat: no-repeat;
          background-position: center 15px;
          background-size: 84px;
        }
        .reality-hotspot-inner {
          position: absolute;
          z-index: 1;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: theme.$darkgreen;
          font-weight: 400;
          font-size: 13px;
        }
      }
      &:hover {
        border: 1px solid theme.$darkgreen;
        button {
          height: 80px;
          width: 80%;
        }
      }
    }*/
    &:not(.link) {
      button {
        width: 80%;
        height: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: theme.$grey;
        border-radius: 100px;
        transition: width 0.3s ease-in-out, height 0.3s ease-in-out,
          background-color 0.3s;
        border: 1px solid theme.$darkgreen;

        &::before {
          content: "";
          background-color: theme.$darkgreen;
          -webkit-mask-image: url("~@/assets/icons/Plant_2.svg");
          mask-image: url("~@/assets/icons/Plant_2.svg");
          width: 100%;
          height: 100%;
          background-size: 50%;
          mask-size: 50%;
          mask-repeat: no-repeat;
          mask-position: center;
        }
        &:hover {
          border: 1px solid theme.$grey;
          background-color: theme.$darkgreen;
          background-image: none;
          &::before {
            content: "";
            background-color: theme.$grey;
            -webkit-mask-image: url("~@/assets/icons/Plant_2.svg");
            mask-image: url("~@/assets/icons/Plant_2.svg");
            width: 100%;
            height: 100%;
            background-size: 50%;
            mask-size: 50%;
            mask-repeat: no-repeat;
            mask-position: center;
          }
        }

        .reality-hotspot-inner {
          position: absolute;
          z-index: 1;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: theme.$darkgreen;
          font-weight: 400;
          font-size: 13px;
        }

        &:focus {
          outline: none;
        }
      }
    }

    &.visited {
      button {
        background-color: rgba(149, 125, 106, 0.6);

        .reality-hotspot-inner {
          color: rgb(149, 125, 106);
        }
      }
    }

    &:hover {
      button {
        //   width: 4.3em;
        //    height: 4.3em;

        &::after {
          width: 20%;
          height: 20%;
        }
      }
    }
  }
</style>
