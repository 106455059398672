export default [
  {
    name: `video_intro`,
    exitButton: `PASSER LA VIDEO`,
    align: `right`,
    subtitle: "/subtitles/fr/intro.vtt",
    src: require(`@/assets/videos/fr/intro.mp4`),
  },
  {
    name: `nurserie`,
    exitButton: `CONTINUER`,
    align: `right`,
    subtitle: "/subtitles/fr/nurserie.vtt",
    src: require(`@/assets/videos/fr/nurserie.mp4`),
  },
  {
    name: `production_2`,
    exitButton: `CONTINUER`,
    align: `right`,
    subtitle: "/subtitles/fr/production.vtt",
    src: require(`@/assets/videos/fr/production.mp4`),
  },
  {
    name: `water_unit`,
    exitButton: `CONTINUER`,
    align: `right`,
    subtitle: "/subtitles/fr/water_unit.vtt",
    src: require(`@/assets/videos/fr/water_unit.mp4`),
  },
  {
    name: `showroom`,
    exitButton: `CONTINUER`,
    align: `right`,
    subtitle: "/subtitles/fr/showroom.vtt",
    src: require(`@/assets/videos/fr/showroom.mp4`),
  },
];
