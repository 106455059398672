var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"spaces-map",class:{
    mobile: _vm.$breakpoints.isMobile,
  }},[_c('button',{staticClass:"buttonmap",on:{"click":function($event){return _vm.$emit(`toggle-map`)}}},[(_vm.mapOver)?_c('img',{attrs:{"src":require("@/assets/icons/map.webp")},on:{"mouseover":function($event){_vm.mapOver = true},"mouseleave":function($event){_vm.mapOver = false}}}):_c('img',{attrs:{"src":require("@/assets/icons/map_hover.webp")},on:{"mouseover":function($event){_vm.mapOver = true},"mouseleave":function($event){_vm.mapOver = false}}})]),_c('transition',{attrs:{"name":"fade","appear":""}},[(_vm.open && _vm.scene && _vm.$breakpoints.isMobile)?_c('div',{staticClass:"reality-mobile-menu-open"},[_c('div',{staticClass:"reality-top-menu"},[_c('button',{staticClass:"reality-menu-sound-btn",on:{"click":function($event){$event.stopPropagation();return _vm.toggleSound.apply(null, arguments)}}},[(_vm.sound)?_c('img',{attrs:{"src":require("@/assets/icons/music_on.gif")}}):_c('img',{attrs:{"src":require("@/assets/icons/music_off.png")}})]),(!_vm.$breakpoints.isMobile && _vm.scene.name != 'couloir')?_c('button',{staticClass:"reality-menu-close-btn",on:{"click":function($event){$event.stopPropagation();return _vm.$emit(`toggle-map`)}}},[_c('img',{attrs:{"src":require("@/assets/icons/cross.svg")}})]):_vm._e()]),_vm._l((_vm.config.elements),function(btn){return _c('button',{key:btn.name,staticClass:"map-button",style:({
          'background-image': 'url(' + btn.background + ')',
        }),on:{"click":function($event){return _vm.$emit(`change-scene`, btn.link)}}},[_c('p',{staticClass:"font-SaolText",domProps:{"innerHTML":_vm._s(btn.title)}})])})],2):_vm._e(),(_vm.open && _vm.scene && !_vm.$breakpoints.isMobile)?_c('div',{staticClass:"map"},[_c('div',{staticClass:"reality-top-menu"},[(!_vm.$breakpoints.isMobile)?_c('button',{staticClass:"reality-menu-fullscreen-btn",on:{"click":function($event){$event.stopPropagation();return _vm.toggleFullscreen.apply(null, arguments)}}},[(_vm.fullscreen)?_c('img',{attrs:{"src":require("@/assets/icons/fullscreen_off.svg")}}):_c('img',{attrs:{"src":require("@/assets/icons/fullscreen_on.svg")}})]):_vm._e(),_c('button',{staticClass:"reality-menu-sound-btn",on:{"click":function($event){$event.stopPropagation();return _vm.toggleSound.apply(null, arguments)}}},[(_vm.sound)?_c('img',{attrs:{"src":require("@/assets/icons/music_on.gif")}}):_c('img',{attrs:{"src":require("@/assets/icons/music_off.png")}})]),(_vm.scene.name != 'couloir')?_c('button',{staticClass:"reality-menu-close-btn",on:{"click":function($event){$event.stopPropagation();return _vm.$emit(`toggle-map`)}}},[_c('img',{attrs:{"src":require("@/assets/icons/cross.svg")}})]):_vm._e()]),_c('p',{staticClass:"font-FoundersGrotesk topText"},[_vm._v(" "+_vm._s(_vm.config.title)+" ")]),_c('div',{staticClass:"rotating-div"},_vm._l((_vm.config.elements),function(btn){return _c('div',{key:btn.name,class:'encart map-' + btn.name},[_c('button',{staticClass:"encart-hover",on:{"click":function($event){return _vm.$emit('change-scene', btn.link)},"mouseover":function($event){return _vm.mouseOver(btn.name)},"mouseleave":function($event){return _vm.mouseLeave(btn.name)}}}),_c('img',{attrs:{"src":_vm.scene.name == btn.name ||
              _vm.scene.name == btn.othername ||
              _vm.room[btn.name]
                ? btn.image.on
                : btn.image.off}}),_c('button',{staticClass:"font-SaolText link",domProps:{"innerHTML":_vm._s(btn.title)},on:{"mouseover":function($event){return _vm.mouseOver(btn.name)},"mouseleave":function($event){return _vm.mouseLeave(btn.name)},"click":function($event){return _vm.$emit('change-scene', btn.link)}}})])}),0)]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }