<template>
  <div
    class="hotspot icon-hotspot"
    :class="{
      visible,
    }"
    :style="`
      top: ${coords.y}%;
      left: ${coords.x}%;
    `"
    @click.prevent="execAction"
  >
    <button>
      <slot></slot>
    </button>
  </div>
</template>

<script>
  import { mapRange } from "@/lib/mathUtils";

  export default {
    props: {
      spotConfig: {
        type: Object,
        required: true,
      },
      action: {
        type: Object,
        required: false,
        default: () => ({ type: ``, args: {} }),
      },
    },

    computed: {
      coords() {
        const coords = {
          x: -100,
          y: -100,
        };

        if (typeof this.spotConfig.coords !== `undefined`) {
          coords.x = mapRange(
            this.spotConfig.coords.x,
            { min: -1, max: 1 },
            { min: 0, max: 100 }
          );
          coords.y = mapRange(
            this.spotConfig.coords.y,
            { min: 1, max: -1 },
            { min: 0, max: 100 }
          );
        }

        return coords;
      },
      visible() {
        return this.spotConfig?.visible || false;
      },
    },

    methods: {
      execAction() {
        if (this.action.type.length) {
          this.$emit(this.action.type, this.action.args);
        }
      },
      async wait(ms) {
        return new Promise((resolve) => {
          setTimeout(() => resolve(), ms);
        });
      },
    },
  };
</script>

<style lang="scss">
  .hotspot {
    position: absolute;
    z-index: 1000;
    transform: translate(-50%, -50%);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.5s ease-in-out;

    &.visible {
      opacity: 1;
      pointer-events: all;
    }

    &.destroyed {
      opacity: 0 !important;
      pointer-events: none !important;
    }

    button {
      cursor: pointer;
      border: none;
      background: none;

      &:focus {
        outline: none;
      }
    }
  }
</style>
