export default {
  name: `map`,
  elements: [
    {
      name: `nurserie`,
      link: `nurserie`,
      image: {
        on: require(`@/assets/map/nurserie_on.webp`),
        off: require(`@/assets/map/nurserie_off.webp`),
      },
    },
    {
      name: `production_2`,
      othername: `production_1`,
      link: `production_2`,
      image: {
        on: require(`@/assets/map/production_on.webp`),
        off: require(`@/assets/map/production_off.webp`),
      },
    },
    {
      name: `water_unit`,
      link: `water_unit`,
      image: {
        on: require(`@/assets/map/water_unit_on.webp`),
        off: require(`@/assets/map/water_unit_off.webp`),
      },
    },
    {
      name: `showroom`,
      othername: `showroom_2`,
      link: `showroom`,
      image: {
        on: require(`@/assets/map/showroom_on.webp`),
        off: require(`@/assets/map/showroom_off.webp`),
      },
    },
  ],
};
