import * as THREE from 'three'
import * as lil from 'lil-gui'

export default class LilGui {
  constructor(ap3) {
    this.init(ap3)
    this.addSpotSelector()
  }

  init(ap3) {
    this.ap3 = ap3
    this.lil = new lil.GUI({ width: 600, title: 'SPOT PLACEMENT' })
    this.lil.close()
    this.lilParams = {
      name: 'Select your spot',
      image: null,
      size: 1,
      longitude: 0,
      azimuth: 0,
      inclinationX: 0,
      inclinationY: 0,
      direction: 0,
      clickShape: null,
      clickScaleX: 1,
      clickScaleY: 1,
      clickOffsetX: 0,
      clickOffsetY: 0
    }
    this.lilControls = {
      name: null,
      image: null,
      size: null,
      longitude: null,
      azimuth: null,
      inclinationX: null,
      inclinationY: null,
      direction: null,
      clickShape: null,
      clickScaleX: null,
      clickScaleY: null,
      clickOffsetX: null,
      clickOffsetY: null
    }
    this.matsSpots = []
    this.curSpot = null
    this.matWireGreen = new THREE.MeshBasicMaterial({ color: 0x00ff00, wireframe: true })
    this.matWireYellow = new THREE.MeshBasicMaterial({ color: 0xffff00, wireframe: true })
    this.spot3DWire = new THREE.Mesh(new THREE.BufferGeometry(), this.matWireYellow)
    this.spotSphere = new THREE.Mesh(new THREE.SphereGeometry(), new THREE.MeshBasicMaterial({color: 0x00ff00}))
    this.spotSphere.scale.set(0.25, 0.25, 0.25)
  }

  addSpotSelector() {
    for(const mat in this.ap3.spots.matsSpots.mats) { this.matsSpots.push(mat) }
    this.lilControls.name = this.lil.add( this.lilParams, 'name', this.ap3.spots.spots2dList.concat(this.ap3.spots.spots3dList) ).name('SPOT')
    this.lilControls.name.onChange( name => {
      if(this.curSpot) { this.resetLilGui() }
      if(this.ap3.spots.spots2dList.includes(name)) { this.selectSpot2d(name) } else
      if(this.ap3.spots.spots3dList.includes(name)) { this.selectSpot3d(name) }
    })
  }

  resetLilGui() {
    if(this.ap3.spots.spots3dList.includes(this.curSpot.charAt(0).toLowerCase() + this.curSpot.slice(1))) {
      this.ap3.spots[`click${this.curSpot}`].visible = false
      for(const control in this.lilControls) { if(control != 'name') { this.lilControls[control].destroy()} }
    } else {
      this.spotSphere.visible = false
      this.lilControls.longitude.destroy()
      this.lilControls.azimuth.destroy()
    }
  }

  selectSpot2d(name) {
    const ucName = name.charAt(0).toUpperCase() + name.slice(1)
    this.curSpot = ucName

    this.ap3.spots[`h${ucName}`].add(this.spotSphere)
    this.spotSphere.visible = true

    this.lilParams.longitude = - this.ap3.spots[`hLon${ucName}`].rotation.y * 180/Math.PI - 90
    this.lilParams.azimuth = - this.ap3.spots[`hAzi${ucName}`].rotation.x * 180/Math.PI

    this.lilControls.longitude = this.lil.add( this.lilParams, 'longitude', -180, 180, 1 )
    this.lilControls.azimuth = this.lil.add( this.lilParams, 'azimuth', -90, 90, 1 )

    this.lilControls.longitude.onChange( value => { this.ap3.spots[`hLon${ucName}`].rotation.y = - (value + 90) * Math.PI/180 })
    this.lilControls.azimuth.onChange( value => { this.ap3.spots[`hAzi${ucName}`].rotation.x = - value * Math.PI/180 })
  }

  selectSpot3d(name) {
    const ucName = name.charAt(0).toUpperCase() + name.slice(1)
    this.curSpot = ucName

    this.ap3.spots[`click${ucName}`].material = this.matWireGreen
    this.ap3.spots[`click${ucName}`].visible = true
    this.spot3DWire.geometry = this.ap3.spots[name].geometry
    this.ap3.spots[`hIncli${ucName}`].add(this.spot3DWire)

    this.lilParams.image = this.ap3.spots[name].material.name
    this.lilParams.size = this.ap3.spots[`hIncli${ucName}`].scale.x
    this.lilParams.longitude = - this.ap3.spots[`hLon${ucName}`].rotation.y * 180/Math.PI - 90
    this.lilParams.azimuth = - this.ap3.spots[`hAzi${ucName}`].rotation.x * 180/Math.PI
    this.lilParams.inclinationX = this.ap3.spots[`hIncli${ucName}`].rotation.x * 180/Math.PI - 180
    this.lilParams.inclinationY = this.ap3.spots[`hIncli${ucName}`].rotation.y * 180/Math.PI
    this.lilParams.direction = - this.ap3.spots[`hIncli${ucName}`].rotation.z * 180/Math.PI + 180
    this.lilParams.clickShape = this.ap3.spots[`click${ucName}`].geometry.name.slice(0,-3)
    this.lilParams.clickScaleX = this.ap3.spots[`click${ucName}`].scale.x
    this.lilParams.clickScaleY = this.ap3.spots[`click${ucName}`].scale.y
    this.lilParams.clickOffsetX = this.ap3.spots[`click${ucName}`].position.x * 2
    this.lilParams.clickOffsetY = this.ap3.spots[`click${ucName}`].position.y * 2

    this.lilControls.image = this.lil.add( this.lilParams, 'image', this.matsSpots )
    this.lilControls.size = this.lil.add( this.lilParams, 'size', 0, 5, 0.01 )
    this.lilControls.longitude = this.lil.add( this.lilParams, 'longitude', -180, 180, 1 )
    this.lilControls.azimuth = this.lil.add( this.lilParams, 'azimuth', -90, 90, 1 )
    this.lilControls.inclinationX = this.lil.add( this.lilParams, 'inclinationX', -90, 90, 1 )
    this.lilControls.inclinationY = this.lil.add( this.lilParams, 'inclinationY', -90, 90, 1 )
    this.lilControls.direction = this.lil.add( this.lilParams, 'direction', -180, 180, 1 )
    this.lilControls.clickShape = this.lil.add( this.lilParams, 'clickShape', ['circle', 'square'] )
    this.lilControls.clickScaleX = this.lil.add( this.lilParams, 'clickScaleX', 0, 1, 0.01 )
    this.lilControls.clickScaleY = this.lil.add( this.lilParams, 'clickScaleY', 0, 1, 0.01 )
    this.lilControls.clickOffsetX = this.lil.add( this.lilParams, 'clickOffsetX', -1, 1, 0.01 )
    this.lilControls.clickOffsetY = this.lil.add( this.lilParams, 'clickOffsetY', -1, 1, 0.01 )
    this.lilControls.image.onChange( value => { this.ap3.spots[name].material = this.ap3.spots.matsSpots.mats[value] })
    this.lilControls.size.onChange( value => {
      this.ap3.spots[`hIncli${ucName}`].scale.set(value, value, value)
      this.ap3.spots[`hIncli${ucName}`].position.z = 10 - Math.pow((2 * Math.pow(value/2, 2)), 0.5)
    })
    this.lilControls.longitude.onChange( value => { this.ap3.spots[`hLon${ucName}`].rotation.y = - (value + 90) * Math.PI/180 })
    this.lilControls.azimuth.onChange( value => { this.ap3.spots[`hAzi${ucName}`].rotation.x = - value * Math.PI/180 })
    this.lilControls.inclinationX.onChange( value => { this.ap3.spots[`hIncli${ucName}`].rotation.x = (180 + value) * Math.PI/180 })
    this.lilControls.inclinationY.onChange( value => { this.ap3.spots[`hIncli${ucName}`].rotation.y = (value) * Math.PI/180 })
    this.lilControls.direction.onChange( value => { this.ap3.spots[`hIncli${ucName}`].rotation.z = (180 - value) * Math.PI/180 })
    this.lilControls.clickShape.onChange( value => { this.ap3.spots[`click${ucName}`].geometry = this.ap3.spots[`${value}Geo`] })
    this.lilControls.clickScaleX.onChange( value => { this.ap3.spots[`click${ucName}`].scale.x = value })
    this.lilControls.clickScaleY.onChange( value => { this.ap3.spots[`click${ucName}`].scale.y = value })
    this.lilControls.clickOffsetX.onChange( value => { this.ap3.spots[`click${ucName}`].position.x = value / 2 })
    this.lilControls.clickOffsetY.onChange( value => { this.ap3.spots[`click${ucName}`].position.y = value / 2 })
  }
}