export default {
  name: `tutorial`,
  title: ``,
  tutorialElements: [
    {
      name: `360_movement`,
      text: `Cliquez et déplacez votre souris à 360°`,
      mobiletext: `Faites glisser votre doigt pour visiter à 360°`,
    },
    {
      name: `hotspots`,
      text: `Cliquez sur les plantes pour en savoir plus`,
    },
    {
      name: `arrows_movement`,
      text: `Déplacez-vous à l'aide des points de déplacement`,
    },
  ],
  continueCTA: `CONTINUER`,
};
