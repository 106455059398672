export default [
  {
    name: `nurserie`,
    label: `The <i>Nursery</i>`,
    img: require(`@/assets/hotspots/en/pepiniere_resultat.webp`),
    img_hover: require(`@/assets/hotspots/en/pepiniere_hover_resultat.webp`),
  },
  {
    name: `production_1`,
    //   label: `La <i>2<sup>nd</sup> salle de production</i>`,
    img: require(`@/assets/hotspots/en/prod_suite.webp`),
    img_hover: require(`@/assets/hotspots/en/prod_suite_hover.webp`),
  },
  {
    name: `production_2`,
    label: `The <i>Production</i> Rooms`,
    img: require(`@/assets/hotspots/en/salles-de-production_resultat.webp`),
    img_hover: require(`@/assets/hotspots/en/salles-de-prod_hover_resultat.webp`),
  },
  {
    name: `water_unit`,
    label: `The <i>Technical Unit</i>`,
    img: require(`@/assets/hotspots/en/unite-technique_resultat.webp`),
    img_hover: require(`@/assets/hotspots/en/unite-technique_hover_resultat.webp`),
  },
  {
    name: `couloir`,
    label: ``,
  },
  {
    name: `showroom`,
    label: `The <i>Showroom</i>`,
    img: require(`@/assets/hotspots/en/showroom_resultat.webp`),
    img_hover: require(`@/assets/hotspots/en/showroom_hover_resultat.webp`),
    hotspots: {
      spots2D: [
        {
          name: `showroom_1`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `showroom_dialog_1`,
            },
          },
          longitude: 171,
          azimuth: -20,
        },
        {
          name: `showroom_2`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `showroom_dialog_2`,
            },
          },
          longitude: -134,
          azimuth: 3,
        },
        {
          name: `showroom_3`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `showroom_dialog_3`,
            },
          },
          longitude: -156,
          azimuth: -23,
        },
        {
          name: `showroom_4`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `showroom_dialog_4`,
            },
          },
          longitude: -136,
          azimuth: -7,
        },
        {
          name: "scene_change_showroom_2",
          type: `basic-spot`,
          category: `link`,
          action: {
            type: `change-scene`,
            args: {
              scene: `showroom_2`,
            },
          },
          longitude: -77,
          azimuth: -5,
        },
      ],
      spots3D: [],
    },
  },
  {
    name: `showroom_2`,
    img: require(`@/assets/hotspots/en/showroom_suite.webp`),
    img_hover: require(`@/assets/hotspots/en/showroom_suite_hover.webp`),
  },
];
