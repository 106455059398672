export default [
  {
    url: require(`@/assets/fonts/SaolText-Regular.otf`),
    name: `SaolText`,
    weight: 200,
  },
  {
    url: require(`@/assets/fonts/SaolText-Bold.otf`),
    name: `SaolText`,
    weight: 400,
  },
  {
    url: require(`@/assets/fonts/SaolText-SemiboldItalic.otf`),
    name: `SaolText`,
    weight: 300,
    fontStyle: "italic, oblique",
  },
  {
    url: require(`@/assets/fonts/SaolText-Semibold.otf`),
    name: `SaolText`,
    weight: 300,
  },
  {
    url: require(`@/assets/fonts/SaolText-LightItalic.otf`),
    name: `SaolText`,
    weight: 100,
    fontStyle: "italic, oblique",
  },
  {
    url: require(`@/assets/fonts/SaolText-Light.otf`),
    name: `SaolText`,
    weight: 100,
  },
  {
    url: require(`@/assets/fonts/FoundersGroteskMonoRegular.otf`),
    name: `FoundersGrotesk`,
    weight: 200,
  },
  {
    url: require(`@/assets/fonts/FoundersGroteskMonoDemiBold.otf`),
    name: `FoundersGrotesk`,
    weight: 400,
  },
];
