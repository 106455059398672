export default {
  name: `menu`,
  entries: [
    {
      name: `nurserie`,
      text: `The <i>nursery</i>`,
    },
    {
      name: `production_2`,
      text: `The <i>production rooms</i>`,
    },
    {
      name: `showroom`,
      text: `The <i>showroom</i>`,
    },
    {
      name: `water_unit`,
      text: `The <i>technical unit</i>`,
    },
  ],
};
