<template>
  <button
    class="bg_slider"
    :style="{ 'background-position': '-' + percentProgression + '% ' + '100%' }"
    @click="close"
  >
    {{ text }}
  </button>
</template>
<script>
  export default {
    props: {
      current: {
        type: Number,
        required: false,
        default: null,
      },
      duration: {
        type: Number,
        required: false,
        default: null,
      },
      text: {
        type: String,
        required: true,
        default: "",
      },
    },
    data: () => ({
      percentProgression: null,
      currentTime: 1,
    }),
    methods: {
      progress() {
        if (this.percentProgression < 100) {
          setTimeout(() => {
            this.currentTime = this.currentTime + 0.1;
            this.percentProgression = (this.currentTime / this.duration) * 100;
            this.progress();
          }, 100);
        }
      },
      close() {
        this.$emit("close");
        this.currentTime = 1;
      },
    },
    watch: {
      duration() {
        this.currentTime = 1;
        this.progress();
      },
    },
  };
</script>
<style lang="scss">
  .bg_slider {
    --initialTextColor: #fff;
    --slideTextColor: #272838;

    --initialBgColor: transparent;
    --slideBgColor: rgba(255, 255, 255, 0.5);

    color: var(--initialTextColor);

    background-image: linear-gradient(
      90deg,
      var(--initialBgColor) 0%,
      var(--initialBgColor) 50%,
      var(--slideBgColor) 50%,
      var(--slideBgColor) 100%
    );
    background-size: 200%;
    transition: background-position 0.3s cubic-bezier(0.47, 0.1, 1, 0.63),
      color 0.2s linear;
    transition-delay: 0s, 0.15s;
    background-position: 0% 100%;
  }

  .bg_slider:hover {
    color: var(--slideTextColor);
    cursor: pointer;
    background-position: -100% 100% !important;
  }
</style>
