<template>
  <div
    class="spaces-map"
    :class="{
      mobile: $breakpoints.isMobile,
    }"
  >
    <button class="buttonmap" @click="$emit(`toggle-map`)">
      <img
        v-if="mapOver"
        src="@/assets/icons/map.webp"
        @mouseover="mapOver = true"
        @mouseleave="mapOver = false"
      />
      <img
        v-else
        src="@/assets/icons/map_hover.webp"
        @mouseover="mapOver = true"
        @mouseleave="mapOver = false"
      />
    </button>
    <transition name="fade" appear>
      <div
        class="reality-mobile-menu-open"
        v-if="open && scene && $breakpoints.isMobile"
      >
        <div class="reality-top-menu">
          <button class="reality-menu-sound-btn" @click.stop="toggleSound">
            <img v-if="sound" src="@/assets/icons/music_on.gif" />
            <img v-else src="@/assets/icons/music_off.png" />
          </button>
          <button
            v-if="!$breakpoints.isMobile && scene.name != 'couloir'"
            class="reality-menu-close-btn"
            @click.stop="$emit(`toggle-map`)"
          >
            <img src="@/assets/icons/cross.svg" />
          </button>
        </div>

        <button
          v-for="btn in config.elements"
          :key="btn.name"
          class="map-button"
          @click="$emit(`change-scene`, btn.link)"
          :style="{
            'background-image': 'url(' + btn.background + ')',
          }"
        >
          <p class="font-SaolText" v-html="btn.title" />
        </button>
      </div>

      <div class="map" v-if="open && scene && !$breakpoints.isMobile">
        <div class="reality-top-menu">
          <button
            v-if="!$breakpoints.isMobile"
            class="reality-menu-fullscreen-btn"
            @click.stop="toggleFullscreen"
          >
            <img v-if="fullscreen" src="@/assets/icons/fullscreen_off.svg" />
            <img v-else src="@/assets/icons/fullscreen_on.svg" />
          </button>
          <button class="reality-menu-sound-btn" @click.stop="toggleSound">
            <img v-if="sound" src="@/assets/icons/music_on.gif" />
            <img v-else src="@/assets/icons/music_off.png" />
          </button>
          <button
            v-if="scene.name != 'couloir'"
            class="reality-menu-close-btn"
            @click.stop="$emit(`toggle-map`)"
          >
            <img src="@/assets/icons/cross.svg" />
          </button>
        </div>
        <p class="font-FoundersGrotesk topText">
          {{ config.title }}
        </p>
        <div class="rotating-div">
          <div
            v-for="btn in config.elements"
            :key="btn.name"
            :class="'encart map-' + btn.name"
          >
            <button
              class="encart-hover"
              @click="$emit('change-scene', btn.link)"
              @mouseover="mouseOver(btn.name)"
              @mouseleave="mouseLeave(btn.name)"
            />
            <img
              :src="
                scene.name == btn.name ||
                scene.name == btn.othername ||
                room[btn.name]
                  ? btn.image.on
                  : btn.image.off
              "
            />
            <button
              @mouseover="mouseOver(btn.name)"
              @mouseleave="mouseLeave(btn.name)"
              class="font-SaolText link"
              @click="$emit('change-scene', btn.link)"
              v-html="btn.title"
            />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  export default {
    props: {
      open: {
        type: Boolean,
        required: true,
      },
      scene: {
        type: Object,
        required: false,
      },
      fullscreen: {
        type: Boolean,
        required: true,
      },
      sound: {
        type: Boolean,
        required: true,
      },
      config: {
        type: Object,
        requierd: true,
      },
    },
    data: () => ({
      mapOver: false,
      room: {
        showroom: false,
        nurserie: true,
        water_unit: false,
        production_2: false,
      },
    }),
    methods: {
      toggleSound() {
        this.$emit(`toggle-sound`);
      },
      toggleFullscreen() {
        this.$emit(`toggle-fullscreen`);
      },
      mouseOver: function (zone) {
        this.room[zone] = true;
      },
      mouseLeave: function () {
        this.room.showroom = false;
        this.room.water_unit = false;
        this.room.nurserie = false;
        this.room.production_2 = false;
      },
    },
  };
</script>
<style scoped lang="scss">
  @use "../assets/css/theme";
  .space-map {
    position: relative;
  }
  .buttonmap {
    position: absolute;
    top: 44px;
    right: 60px;
    z-index: 8;
    transition: all 0.3s;
    user-select: none;
    &:hover {
      padding: 3px;
      img {
        width: 65px;
      }
    }
    img {
      transition: all 0.3s;
      width: 70px;
      height: auto;
    }
  }
  .encart-hover {
    width: 70%;
    height: 123%;
    position: absolute;
    left: 15%;
    transform: rotate3d(6.4, -2.3, 5, 69deg);
    top: -10%;
  }

  .reality-top-menu {
    position: absolute;
    top: 60px;
    right: 60px;
    display: flex;
    gap: 15px;
    z-index: 10;
    button {
      width: 55px;
      height: 55px;
      img {
        width: 25px;
      }
    }
    .reality-menu-close-btn {
      border: none;
      background: theme.$darkgreen;
      border-radius: 100%;
      font-size: 30px;
      line-height: 17px;
    }
    .reality-menu-fullscreen-btn,
    .reality-menu-close-btn {
      transition: color 0.5s ease-in-out, all 0.5s ease-in-out;
      img {
        transition: color 0.5s ease-in-out, all 0.3s ease-in-out;
        filter: brightness(0) invert(1);
        width: 25px;
      }
      &:hover {
        width: 45px;
        height: 45px;
        margin: 5px;
        img {
          width: 20px;
        }
      }
    }

    .reality-menu-sound-btn {
      border-radius: 100%;
      line-height: 17px;
      width: 55px;
      height: 55px;
      img {
        filter: brightness(0) invert(1);
        width: 55px;
      }
    }
  }
  .reality-mobile-menu-open {
    position: absolute;
    width: 100%;
    height: 100vh;
    height: -webkit-fill-available;
    top: 0px;
    left: 0px;
    z-index: 12;
    background-color: theme.$darkgreen;
    background-image: url("~@/assets/map/pattern.webp");

    .map-button {
      position: relative;
      height: 25%;
      width: 100vw;
      background-size: contain;
      background-position: center;
    }
    p {
      position: absolute;
      bottom: 0px;
      color: #fff;
      width: 100%;
      text-align: left;
      padding: 6vw;
      font-size: 7vw;
      font-weight: 300;
      background-image: linear-gradient(
        to bottom,
        transparent 25%,
        rgba(0, 0, 0, 0.33)
      );
    }
    img {
      width: 100%;
    }
  }

  .map {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 10;
    background-color: theme.$darkgreen;
    background-image: url("~@/assets/map/pattern.webp");
    z-index: 12;
    .rotating-div {
      display: inline-block;
      height: 100vh;
      min-height: 645px;
      width: 60%;
      position: relative;
      max-width: 1000px;
      .encart {
        width: 50%;
        height: auto;
        position: absolute;
        top: 52%;
        left: 50%;
        img {
          width: 100%;
        }
        &.map-showroom {
          transform: translate(-127%, -50%) !important;
        }
        &.map-water_unit {
          transform: translate(-50%, 17%) !important;
        }
        &.map-nurserie {
          transform: translate(-50%, -115%) !important;
        }
        &.map-production_2 {
          transform: translate(32%, -50%) !important;
        }
        .link {
          color: theme.$darkgreen;
          width: 170px;
          height: 80px;
          background-color: rgba(theme.$grey, 1);
          border-radius: 0;
          border: 1px solid rgba(theme.$grey, 1);
          position: absolute;
          bottom: 0px;
          left: 50%;
          transform: translate(-50%);
          &:hover {
            background-image: url("~@/assets/icons/arrow_animated.svg");
            border-radius: 0;
            background-repeat: no-repeat;
            background-position: center 15px;
            background-size: 84px;
            border: 1px solid theme.$darkgreen;
          }
        }
      }
    }
    .topText {
      text-align: center;
      color: white;
      font-size: 2vw;
      width: 100%;
      position: absolute;
      top: 5%;
    }
  }

  .mobile {
    .reality-top-menu {
      top: 16px;
      right: 16px;
      flex-direction: column-reverse;
    }
    .buttonmap {
      top: 13px;
      right: 18px;
      width: 74px;
      height: 63px;
      img {
        width: 54px;
      }
    }
  }
</style>
