<template>
  <div id="spaces-visit" :class="{ visible, mobile: $breakpoints.isMobile }">
    <a href="/" class="noclick"
      ><img class="logo" src="@/assets/logos/logo_white.svg" alt="Ulé"
    /></a>
    <spaces-map
      :scene="currentScene"
      :open="isMapOpened"
      @toggle-map="toggleMap"
      :sound="isSound"
      :config="mapConfig"
      :fullscreen="fullscreen"
      @change-scene="changeSceneFromParent"
      @toggle-sound="toggleSound"
      @toggle-fullscreen="toggleFullscreen"
    />
    <!--  <reality-menu
      :open="isMenuOpened"
      :menu="menu"
      :sound="isSound"
      @toggle-sound="toggleSound"
      @toggle-menu="toggleMenu"
      @toggle-fullscreen="toggleFullscreen"
      @change-scene="changeSceneFromParent"
    /> -->
    <transition name="fade" :duration="{ enter: 800, leave: 800 }">
      <spaces-scene
        :scene="currentScene"
        :scenes-config="scenes"
        :muted="muted || isSceneMuted"
        :gyro-enabled="gyroEnabled"
        :render="render"
        :gyro-datas="gyroDatas"
        :dialog-open="isDialogOpened"
        :visited-scenes="visitedScenes"
        @scene-mounted="sceneMounted"
        @change-scene="changeScene"
        @open-dialog="openDialog"
        @close-dialog="closeAllDialogs"
        ref="spacesScene"
        v-if="currentScene"
    /></transition>
    <spaces-video
      :video="currentSpacesVideo"
      :visible="isSpacesVideoVisible"
      :controls="true"
      :muted="muted"
      @close="closeSpacesVideo"
    />
    <transition name="fade" :duration="{ enter: 100, leave: 800 }">
      <intro-video
        :video="introVideo"
        :visible="isIntroVideoOpened"
        @close="closeIntroVideo"
      />
    </transition>
    <transition name="fade">
      <h2
        v-if="currentScene && showTitle && currentScene.label"
        v-html="currentScene.label"
    /></transition>
    <reality-dialog
      :visible="isDialogOpened"
      :name="currentDialogName"
      :dialog="currentDialog"
      :muted="muted"
      @open-video="openSpacesVideo"
      @close="closeDialog"
    />
  </div>
</template>

<script>
  import SpacesScene from "@/components/SpacesScene";
  import SpacesVideo from "@/components/SpacesVideo";
  //import RealityMenu from "@/components/RealityMenu";
  import RealityDialog from "@/components/RealityDialog";
  import IntroVideo from "@/components/IntroVideo";
  import SpacesMap from "@/components/SpacesMap";

  export default {
    components: {
      SpacesScene,
      SpacesVideo,
      //  RealityMenu,
      RealityDialog,
      IntroVideo,
      SpacesMap,
    },

    props: {
      visible: {
        type: Boolean,
        required: false,
        default: () => false,
      },
      muted: {
        type: Boolean,
        required: false,
        default: () => false,
      },
      render: {
        type: Boolean,
        required: false,
        default: () => true,
      },
      fullscreen: {
        type: Boolean,
        required: false,
        default: () => true,
      },
      visit: {
        type: Object,
        required: false,
        default: () => ({}),
      },
      currentExperience: {
        type: String,
        required: true,
      },
      lang: {
        type: Object,
        required: false,
        default: () => null,
      },
      gyroEnabled: {
        type: Boolean,
        required: false,
        default: () => false,
      },
      gyroDatas: {
        type: Object,
        required: false,
        default: () => ({}),
      },
      mapConfig: {
        type: Object,
        required: true,
        default: () => null,
      },
    },

    data: () => ({
      isMenuOpened: false,
      isMapOpened: true,
      isSound: true,
      currentDialogName: `pepiniere_hotspot_1`,
      isDialogOpened: false,
      previousDialogName: null,
      currentSpacesVideo: null,
      isSpacesVideoVisible: false,
      firstSceneName: `couloir`,
      isChangingScene: false,
      previousSceneName: null,
      currentSceneName: null,
      isSceneVisible: false,
      isSceneTicking: false,
      areHotspotsVisible: false,
      isSceneMuted: false,
      currentGem: ``,
      foundGems: {
        green: false,
        blue: false,
        red: false,
        white: false,
      },
      visitedScenes: null,
      isIntroVideoOpened: false,
      showTitle: false,
    }),

    computed: {
      scenes() {
        return this.visit?.scenes || [];
      },
      dialogs() {
        return this.visit?.dialogs || [];
      },
      menu() {
        return this.visit?.menu || {};
      },
      tutorials() {
        return this.visit?.tutorials || [];
      },
      currentScene() {
        let currentScene = null;
        if (this.currentSceneName) {
          currentScene = this.getSceneByName(this.currentSceneName);
        }
        if (currentScene && currentScene.video && this.introVideo) {
          this.$emit("pause-sound");
          this.openIntroVideo();
        } else {
          this.showTitleEvent();
        }
        return currentScene || null;
      },
      previousScene() {
        let previousScene = null;
        if (this.previousSceneName) {
          previousScene = this.getSceneByName(this.previousSceneName);
        }

        return previousScene || null;
      },
      currentDialog() {
        return (
          this.dialogs.find(
            (dialog) => dialog.name === this.currentDialogName
          ) || {}
        );
      },
      introVideo() {
        let video = this.visit.videos.find(
          (video) => video.name === this.currentSceneName
        );
        if (video) {
          video = JSON.parse(JSON.stringify(video));
          let videosStorage = JSON.parse(sessionStorage.getItem("videos")) || [];

          if(videosStorage.includes(video.name)){
            return null;
          }


          videosStorage.push(video.name)
          sessionStorage.setItem(
            "videos",
            JSON.stringify(videosStorage)
          );
        }
        return video || null;
      },
    },

    methods: {
      async wait(ms) {
        return new Promise((resolve) => {
          setTimeout(() => resolve(), ms);
        });
      },
      openIntroVideo() {
        this.isIntroVideoOpened = true;
      },
      async findVideo(vid) {
        return vid.find((video) => video.name === this.currentSceneName);
      },

      closeIntroVideo() {
        this.isIntroVideoOpened = false;
        this.$emit("resume-sound");
        this.showTitleEvent();
      },
      async showTitleEvent() {
        this.showTitle = false;
        this.showTitle = true;
        await this.wait(6000);
        this.showTitle = false;
      },
      toggleMenu() {
        this.isMenuOpened = !this.isMenuOpened;
      },
      toggleMap() {
        this.isMapOpened = !this.isMapOpened;
      },
      toggleSound() {
        this.isSound = !this.isSound;
        this.isSound ? this.$emit("unmute") : this.$emit("mute");
      },
      closeMenu() {
        this.isMenuOpened = false;
      },
      closeMap() {
        this.isMapOpened = false;
      },
      toggleFullscreen() {
        this.$emit(`toggle-fullscreen`);
      },
      getDialogByName(dialogName) {
        return this.dialogs.find((dialog) => dialog.name === dialogName);
      },
      getSceneByName(sceneName) {
        return this.scenes.find((scene) => scene.name === sceneName);
      },
      getTutorialByName(tutorialName) {
        return this.tutorials.find((tuto) => tuto.name === tutorialName);
      },
      openSpacesVideo(video) {
        this.currentSpacesVideo = video;
        this.isSpacesVideoVisible = true;
      },
      async closeSpacesVideo() {
        this.isSpacesVideoVisible = false;
        await this.wait(500);
        this.currentSpacesVideo = null;
      },
      resetZoom() {
        document.body.style.transform = `scale(1)`;
        document.body.style.msTransform = `scale(1)`;
      },
      pauseSound() {
        this.$emit(`pause-sound`);
      },
      resumeSound() {
        this.$emit(`resume-sound`);
      },
      toggleTutorial() {
        this.isTutorialOpened = !this.isTutorialOpened;
      },
      resumeSoundAfterDialogClose() {
        if (!this.currentScene.hasSound) {
          this.resumeSound();
        }
      },
      async startOverExperience() {
        this.changeSceneFromParent(`couloir`);
        setTimeout(() => {
          this.foundGems = {
            green: false,
            blue: false,
            red: false,
            white: false,
          };
          localStorage.setItem(
            "realityFoundGems",
            JSON.stringify(this.foundGems)
          );
        }, 500);
      },
      changeSceneFromParent(scene) {
        if (scene === this.currentSceneName) {
          this.closeMenu();
          setTimeout(() => {
            this.closeMap();
          }, 100);
        } else {
          this.$refs.spacesScene.changeSceneFromParent(scene);
          setTimeout(() => {
            this.closeMap();
          }, 100);
        }
      },
      async changeScene(sceneName) {
        if (this.isChangingScene || this.currentSceneName === sceneName) {
          return;
        }

        this.isChangingScene = true;

        await this.wait(100);
        this.currentSceneName = sceneName;
        this.saveVisitedScene(sceneName);
      },
      async sceneMounted() {
        this.isChangingScene = false;
        await this.wait();
        this.isSceneTicking = true;
        this.isSceneVisible = true;
        this.areHotspotsVisible = true;
      },
      openHub() {
        this.changeScene(`hub`);
      },
      goHome() {
        if (this.currentSceneName !== this.firstSceneName) {
          this.changeScene(this.firstSceneName);
        }
      },
      openDialog(dialogName) {
        this.currentDialogName = dialogName;
        this.$emit(`pause-sound`);
        this.isDialogOpened = true;
      },
      async closeDialog() {
        this.isDialogOpened = false;
        this.$emit(`resume-sound`);
        await this.wait(500);
        this.currentDialogName = ``;
      },
      closeAllDialogs() {
        this.closeDialog();
      },
      loadVisitedScenes() {
        this.visitedScenes = JSON.parse(
          localStorage.getItem(`realityVisitedScenes`)
        );
        if (!this.visitedScenes) {
          this.visitedScenes = {};
          for (const s of this.scenes) {
            if (s.name === `global`) {
              continue;
            }
            this.visitedScenes[s.name] = false;
          }
          localStorage.setItem(
            `realityVisitedScenes`,
            JSON.stringify(this.visitedScenes)
          );
        }
      },
      saveVisitedScene(name) {
        if (
          !Object.keys(this.visitedScenes).includes(name) ||
          this.visitedScenes[name] === true
        ) {
          return;
        }
        this.visitedScenes[name] = true;
        localStorage.setItem(
          `realityVisitedScenes`,
          JSON.stringify(this.visitedScenes)
        );
      },
    },

    created() {
      this.loadVisitedScenes();
    },

    mounted() {
      if (!localStorage.getItem(`realityFoundGems`)) {
        localStorage.setItem(
          `realityFoundGems`,
          JSON.stringify(this.foundGems)
        );
      }
      this.foundGems = JSON.parse(localStorage.getItem(`realityFoundGems`));
      setTimeout(() => {
        this.changeScene(this.firstSceneName);
        if (!this.currentDialog) {
          this.currentDialog = this.dialogs[0];
        }
      });
    },

    watch: {
      currentExperience(newExp) {
        this.changeScene(`${newExp}`);
      },
    },
  };
</script>

<style lang="scss" scoped>
  #position-helper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 5px;
    height: 5px;
    border-radius: 20px;
    background-color: tomato;
  }

  h2 {
    z-index: 7;
    position: absolute;
    color: #fff;
    bottom: 0px;
    left: 0px;
    font-size: 100px;
    font-weight: 200;
    pointer-events: none;
    width: 100%;
    text-align: left;
    padding: 50px;
    bottom: 0px;
    left: 0px;
    background: linear-gradient(
      to bottom,
      transparent 20%,
      rgba(69, 109, 69, 0.73)
    );
  }

  .mobile h2 {
    bottom: 0px;
    left: 0px;
    font-size: 7vw;
    padding: 50px 20px;
    font-weight: 200;
  }
  .spaces-visit {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .lose-message {
    width: fit-content;
    position: absolute;
    transform: translate(-50%, 0);
    padding: 10px 30px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 30px;
    color: #fff;
    font-size: 16px;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.4s;

    &.visible {
      opacity: 1;
    }
  }

  .csr-video-dialog {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: opacity 0.5s ease-in-out;
    opacity: 0;
    pointer-events: none;
    &.visible {
      opacity: 1;
      pointer-events: all;
    }
  }
</style>
