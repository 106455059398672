export default {
  name: `tutorial`,
  tutorialElements: [
    {
      name: `360_movement`,
      //video: require(`@/assets/tutorial/arrow.mp4`),
      gif: require(`@/assets/tutorial/souris.gif`),
      mobilegif: require(`@/assets/tutorial/finger.gif`),
    },
    {
      name: `hotspots`,
      //video: require(`@/assets/tutorial/arrows_movement.mp4`),
      gif: require(`@/assets/tutorial/plant.gif`),
    },
    {
      name: `arrows_movement`,
      //video: require(`@/assets/tutorial/hotspots.mp4`),
      gif: require(`@/assets/tutorial/click.gif`),
      mobilegif: require(`@/assets/tutorial/touch.gif`),
    },
  ],
};
