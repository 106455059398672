export default {
  name: `map`,
  title: `Sélectionnez une salle à visiter`,
  elements: [
    {
      name: `nurserie`,
      title: `la <i>Pépinière</i>`,
    },
    {
      name: `production_2`,
      title: `les salles de <i>Production</i>`,
    },
    {
      name: `water_unit`,
      title: `L'unité <i>Technique</i>`,
    },
    {
      name: `showroom`,
      title: `le <i>Showroom</i>`,
    },
  ],
};
