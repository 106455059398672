export default {
  name: `menu`,
  entries: [
    {
      name: `nurserie`,
      text: `La <i>pépinière</i>`,
    },
    {
      name: `production_2`,
      text: `Les <i>salles de production</i>`,
    },
    {
      name: `showroom`,
      text: `Le <i>Showroom</i>`,
    },
    {
      name: `water_unit`,
      text: `L' <i>unité technique</i>`,
    },
  ],
};
