export default {
  name: `map`,
  title: `Select a room to visit`,
  elements: [
    {
      name: `nurserie`,
      title: `The <i>Nursery</i>`,
    },
    {
      name: `production_2`,
      title: `The <i>Production</i> Rooms`,
    },
    {
      name: `water_unit`,
      title: `The Technical <i>Unit</i>`,
    },
    {
      name: `showroom`,
      title: `The <i>Showroom</i>`,
    },
  ],
};
