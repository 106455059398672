export default [
  {
    name: `pepiniere1`,
    title: `Préparation des semis et boutures`,
    sound: require(`@/assets/dialogs/sounds/fr/nurserie_1.mp3`),
    content: [
      `Il s'agit de la <b>première étape</b> de culture des plantes. <b>Les graines</b> de Tulsi <b>et les boutures</b> de Coleus et de Centella sont insérées <b>dans une éponge.</b>`,
      `L'éponge permet à la plante de mieux s'enraciner et apporte l'humidité nécessaire à son développement. `,
    ],
  },
  {
    name: `pepiniere2`,
    title: `Culture par hydroponie`,
    sound: require(`@/assets/dialogs/sounds/fr/nurserie_2.mp3`),
    content: [
      `Les plantes sont cultivées hors-sol. Des "<b>piscines hydroponiques</b>", présentes à chaque étage, reçoivent une <b>eau riche en minéraux et nutriments</b> qui irrigue les plantes par leurs racines.`,
    ],
    videoCTA: `VOIR LA VIDÉO MÉTIER`,
  },
  {
    name: `pepiniere3`,
    title: `Brumisateur`,
    sound: require(`@/assets/dialogs/sounds/fr/nurserie_3.mp3`),
    content: [
      `Chaque étage dispose d'un <b>brumisateur d'eau pure</b> qui s'active automatiquement afin de maintenir un <b>environnement humide optimal</b> pour la croissance des plantes.`,
    ],
  },
  {
    name: `pepiniere4`,
    title: `Eclairage LED`,
    sound: require(`@/assets/dialogs/sounds/fr/nurserie_4.mp3`),
    content: [
      `Chaque étage est équipé de LEDs, un <b>éclairage durable</b> qui permet une consommation réduite en énergie. `,
      `Les LEDs reproduisent les <b>conditions naturelles d'ensoleillement</b> dont chaque plante a besoin pour permettre de cultiver des plantes toute l'année, sans contrainte saisonnière et avec une qualité constante.`,
    ],
    videoCTA: `VOIR LA VIDÉO MÉTIER`,
  },
  {
    name: `pepiniere5`,
    title: `Développement des racines`,
    sound: require(`@/assets/dialogs/sounds/fr/nurserie_5.mp3`),
    content: [
      `Les <b>ingénieurs agronomes</b> vérifient quotidiennement la <b>croissance des racines</b>. Il faut 2 à 3 semaines pour que les plantes aient suffisamment de racines avant d'être transférées dans les tours verticales des salles de culture où elles se développeront jusqu'à maturité.`,
    ],
  },
  {
    name: `prod_dialog_1`,
    title: `Transfert dans les tours verticales`,
    sound: require(`@/assets/dialogs/sounds/fr/prod_1.mp3`),
    content: [
      `Les plantes sont <b>transférées à la main</b> de la pépinière vers les <b>tours verticales</b> pour démarer leur 2ème phase de croissance. `,
      `Chaque tour mesure 3m et peut contenir jusqu'à 400 plantes d'un même type. La ferme verticale offre un cocon unique où <b>60 paramètres</b> ont été établis (température, humidité, luminosité,...) pour permettre aux plantes d'exprimer tout leur potentiel.  `,
    ],
  },
  {
    name: `prod_dialog_2`,
    title: `Culture par aéroponie`,
    sound: require(`@/assets/dialogs/sounds/fr/prod_2.mp3`),
    content: [
      `Les plantes sont <b>cultivées hors-sol</b>. A l'intérieur de chaque tour verticale, une <b>eau riche en nutriments</b> est pulvérisée sur les racines des plantes.`,
      `Ce qui n'est pas absorbé par les racines retourne vers l'unité technique pour être traité. Ce système en circuit fermé permet de <b>recycler 95% de l'eau</b> utilisée.`,
    ],
  },
  {
    name: `prod_dialog_3`,
    title: `Eclairage LED`,
    sound: require(`@/assets/dialogs/sounds/fr/prod_3.mp3`),
    content: [
      `Les plantes n'étant pas dans leur univers naturel, chaque tour est équipée de <b>LEDs</b> bleues et rouges qui <b>imitent la lumière du soleil</b> et reproduisent le cycle jour/nuit. `,
      `Afin que toutes les plantes soient exposées à la même intensité de lumière au cours de leur croissance, les tours pivotent sur elles-mêmes.`,
    ],
  },
  {
    name: `prod_dialog_4`,
    title: `Récolte des plantes`,
    sound: require(`@/assets/dialogs/sounds/fr/prod_4.mp3`),
    content: [
      `Les plantes se développent sur les tours verticales pendant plusieurs semaines. Leur croissance est contrôlée quotidiennement par les ingénieurs agronomes afin de déterminer le meilleur moment pour démarrer la <b>récolte, entièrement faite à la main.</b>`,
    ],
  },
  {
    name: `prod_dialog_5`,
    title: `Récolte des boutures`,
    sound: require(`@/assets/dialogs/sounds/fr/prod_5.mp3`),
    content: [
      `Les <b>boutures</b> de Coleus et de Centella sont prélevées à la main pour <b>rejoindre la pépinière</b> où elles seront insérées dans une éponge et donneront de nouvelles plantes.`,
    ],
  },
  {
    name: `prod_dialog_6`,
    title: `Coleus`,
    sound: require(`@/assets/dialogs/sounds/fr/prod_6.mp3`),
    content: [
      `- Nom latin : COLEUS FORSKOHLII`,
      `- Action principale : <b>Protéger la peau</b>`,
      `- Résultats* : +51% croissance cellulaire, +35% protection cellulaire`,
      ``,
      `Le <b>Coleus</b> a été sélectionné par nos experts pour sa richesse en <b>antioxydants</b> qui protègent la peau du stress oxydatif et optimisent sa résilience. `,
      ``,
      `*Analyse protéomique`,
    ],
  },
  {
    name: `prod_dialog_7`,
    title: `Centella`,
    sound: require(`@/assets/dialogs/sounds/fr/prod_7.mp3`),
    content: [
      `- Nom latin : CENTELLA ASIATICA`,
      `- Action principale : <b>Régénérer la peau</b>`,
      `- Résultats d'efficacité* : +28% renouvellement cellulaire, +28% augmentation du collagène. `,
      ``,
      `La <b>Centella</b> a été sélectionnée par nos experts car elle stimule la synthèse de <b>collagène</b> et aide à revitaliser la surface de la peau.`,
      ``,
      `*Analyse protéomique `,
    ],
  },
  {
    name: `prod_dialog_8`,
    title: `Tulsi`,
    sound: require(`@/assets/dialogs/sounds/fr/prod_8.mp3`),
    content: [
      `- Nom latin : OCIMUM TENUIFLORUM`,
      `- Action principale : <b>Déstresser la peau</b>`,
      `- Résultats d'efficacité* :+31% protection contre le stress oxydatif, +35% renforcement de la fonction barrière`,
      ``,
      `Le <b>Tulsi</b> est une plante adaptogène connue pour sa capacité à gérer le <b>stress oxydatif</b> et à renforcer la barrière cutanée. Ses propriétés déstressantes permettent d'optimiser la résilience de la peau.`,
      ``,
      `*Analyse protéomique`,
    ],
  },
  {
    name: `water_dialog_1`,
    title: `Préparation de la solution`,
    sound: require(`@/assets/dialogs/sounds/fr/water_1.mp3`),
    content: [
      `Chaque salle de la ferme verticale possède sa propre <b>réserve d'eau</b>, appelée "citerne". Les ingénieurs agronomes établissent pour chaque citerne le <b>juste dosage d'eau et de nutriments</b> qui permet un développement optimal des plantes. Une fois calibrée, la solution est acheminée vers les plantes. `,
    ],
  },
  {
    name: `water_dialog_2`,
    title: `Recyclage de l'eau`,
    sound: require(`@/assets/dialogs/sounds/fr/water_2.mp3`),
    content: [
      `L'eau qui n'est pas absorbée par les plantes retourne vers la citerne pour être filtrée, enrichie en nutriments et remise dans le circuit. Ce système permet de <b>recycler 95% de l'eau utilisée.</b>`,
    ],
  },
  {
    name: `water_dialog_3`,
    title: `Contrôle du pH`,
    sound: require(`@/assets/dialogs/sounds/fr/water_3.mp3`),
    content: [
      `En plus du contrôle de la qualité de l'eau, les ingénieurs agronomes vérifient quotidiennement le <b>pH de la solution</b> en réalisant des prélèvements directement depuis la citerne.`,
    ],
  },
  {
    name: `showroom_dialog_1`,
    title: `Biome Essentials`,
    sound: require(`@/assets/dialogs/sounds/fr/showroom_6.mp3`),
    content: [
      `Une peau saine commence par une bonne base. Découvrez nos <b>Biome Essentials</b>, des gestes simples pour <b>équilibrer</b> le microbiome de la peau, <b>renforcer</b> la barrière cutanée et <b>préparer</b> votre peau à recevoir la suite de votre routine beauté.`,
    ],
    link: {
      link: "https://www.ulebeauty.com/fr/essentials",
      text: "acheter",
    },
  },
  {
    name: `showroom_dialog_2`,
    title: `Active Serums`,
    sound: require(`@/assets/dialogs/sounds/fr/showroom_5.mp3`),
    content: [
      `Nos <b>sérums</b> peuvent être utilisés en superposition ou en application localisée, selon votre humeur et les besoins de votre peau. Chaque sérum est composé de 55% de Pure 3otany™ Blend qui améliore la résilience de la peau,  pour une <b>action ciblée et renforcée</b>. Besoin d'hydratation, de fermeté, d'éclat, ou de confort ? Nous avons forcément le(s) sérum(s) qu'il vous faut !`,
    ],
    link: {
      link: "https://www.ulebeauty.com/fr/serums",
      text: "acheter",
    },
  },
  {
    name: `showroom_dialog_3`,
    title: `Fortifying Creams`,
    sound: require(`@/assets/dialogs/sounds/fr/showroom_4.mp3`),
    content: [
      `Avec l'âge, les fonctions essentielles de la peau diminuent. Nos <b>crèmes fortifiantes</b> sont élaborées à partir du Pure 3otany™ Blend et d'ingrédients actifs ciblés qui améliorent la <b>résilience naturelle de votre peau</b>. Hydratée, nourrie, protégée, votre peau est mieux préparée à gérer le stress, plus saine et plus radieuse que jamais !`,
    ],
    link: {
      link: "https://www.ulebeauty.com/fr/creams",
      text: "acheter",
    },
  },
  {
    name: `showroom_dialog_4`,
    title: `Nutri Beauty`,
    sound: require(`@/assets/dialogs/sounds/fr/showroom_3.mp3`),
    content: [
      `La beauté commence de l'intérieur. Notre gamme <b>Nutri Beauty</b> a été développée en collaboration avec une <b>nutritionniste</b>. Vitamines, super-aliments et minéraux essentiels ont été sélectionnés pour combler les carences nutritionnelles, <b>améliorer le bien-être</b> intestinal et renforcer l'immunité, pour une peau saine et rayonnante !`,
    ],
    link: {
      link: "https://www.ulebeauty.com/fr/nutri",
      text: "acheter",
    },
  },
  {
    name: `showroom_dialog_5`,
    title: `Collection Lifestyle`,
    sound: require(`@/assets/dialogs/sounds/fr/showroom_2.mp3`),
    content: [
      `Découvrez notre collection d'<b>accessoires lifestyle</b>, co-développés avec des marques engagées et responsables.`,
    ],
    link: {
      link: "https://www.ulebeauty.com/fr/lifestyle",
      text: "acheter",
    },
  },
  {
    name: `showroom_dialog_6`,
    title: `Soins Facialistes`,
    sound: require(`@/assets/dialogs/sounds/fr/showroom_1.mp3`),
    content: [
      `Nous avons développé des <b>soins visage et corps</b>, où les méthodes ancestrales de massage fusionnent avec la technologie high-tech pour un moment de bien-être absolu. Découvrez nos soins au <b>Spa TOO Chill</b>, situé au 17ème étage du TOO Hôtel, avec une vue imprenable sur Paris et la Seine.`,
    ],
    link: {
      link: "https://toohotel.com/spa/",
      text: "en savoir plus",
    },
  },
];
