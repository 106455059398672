export default {
  name: `tutorial`,
  title: ``,
  tutorialElements: [
    {
      name: `360_movement`,
      text: `Click and move your mouse 360°`,
      mobiletext: `Swipe your finger to visit in 360°`,
    },
    {
      name: `hotspots`,
      text: `Click on the plant icons to learn more`,
    },
    {
      name: `arrows_movement`,
      text: `Move around using the buttons`,
    },
  ],
  continueCTA: `CONTINUE`,
};
