<template>
  <div
    class="splash-screen light-mode"
    :class="{
      visible,
      mobile: $breakpoints.isMobile,
    }"
    :style="`
      background: ${config.images.background};
    `"
  >
    <a href="/" target="_blank">
      <img
        class="logo"
        :src="config.images.logo.img"
        :alt="config.images.logo.alt"
      />
    </a>
    <h2 class="splash-reality-typo font-SaolText" v-html="config.title" />
    <div class="splash-content">
      <div class="splash-catch-phrase" v-html="config.lang" />
      <language-selector :langs="languages" @choose-lang="langChosen" />
    </div>
  </div>
</template>

<script>
  import LanguageSelector from "@/components/LanguageSelector";

  export default {
    components: {
      LanguageSelector,
    },

    props: {
      visible: {
        type: Boolean,
        required: false,
        default: () => false,
      },
      config: {
        type: Object,
        required: true,
      },
      languages: {
        type: Array,
        required: true,
      },
    },

    data: () => ({
      currentLanguage: null,
    }),

    computed: {
      defaultLanguage() {
        return this.languages.find((lang) => lang.isDefault === true);
      },
      startCTA() {
        return this.currentLanguage
          ? this.currentLanguage.startCTA
          : this.defaultLanguage.startCTA;
      },
    },

    methods: {
      startExperience() {
        this.$emit(`close`);
      },
      langChosen(language) {
        this.currentLanguage = language;
        this.$emit(`choose-lang`, language);
        this.startExperience();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .splash-screen {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: opacity 0.5s ease-in-out;
    opacity: 0;
    pointer-events: none;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;

    &.visible {
      opacity: 1;
      pointer-events: all;
    }

    .splash-reality-typo {
      width: 50%;
      position: absolute;
      margin-top: 10vh;
      font-weight: 100;
      font-size: 70px;
      @media screen and (min-width: 800px) and (max-width: 1750px) {
        font-size: 40px;
      }
    }

    .splash-content {
      position: relative;
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @media screen and (min-width: 800px) and (max-width: 1450px) {
        margin-top: 100px;
      }

      & > * {
        margin: 1.5em 0;
      }

      .splash-reality-logo {
        width: calc(50% * 1 / 3);
      }

      .splash-catch-phrase {
        font-weight: 300;
        font-size: 30px;

        & > span {
          font-style: italic;
        }
      }

      .spacer {
        width: 10%;
        height: 1px;
        background-color: #000000;
      }

      .language-selector {
        width: 40%;
        font-size: 0.8em;
      }
    }

    &.mobile {
      .splash-reality-typo {
        font-size: 7vw;
        margin-top: 10vh;
      }
      .splash-catch-phrase {
        font-size: 8vw;
      }
      .splash-content {
        width: 90%;
        font-size: 0.8em;

        .language-selector {
          width: 80%;
        }
      }
    }
  }
</style>
