export default [
  {
    name: `video_intro`,
    src: require(`@/assets/videos/en/intro.mp4`),
    exitButton: `SKIP THE VIDEO`,
    align: `right`,
  },
  {
    name: `nurserie`,
    src: require(`@/assets/videos/en/nurserie.mp4`),
    exitButton: `CONTINUE`,
    align: `right`,
  },
  {
    name: `production_2`,
    src: require(`@/assets/videos/en/production.mp4`),
    exitButton: `CONTINUE`,
    align: `right`,
  },
  {
    name: `water_unit`,
    src: require(`@/assets/videos/en/water_unit.mp4`),
    exitButton: `CONTINUE`,
    align: `right`,
  },
  {
    name: `showroom`,
    src: require(`@/assets/videos/en/showroom.mp4`),
    exitButton: `CONTINUE`,
    align: `right`,
  },
];
