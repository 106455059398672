<template>
  <tracked-ui
    class="label-hotspot"
    :class="{
      visited,
    }"
    :spot-config="spotConfig"
  >
    <div class="label">
      {{ spotConfig.label }}
    </div>
  </tracked-ui>
</template>

<script>
  import TrackedUi from "@/components/HotspotUi/TrackedUi";

  export default {
    components: {
      TrackedUi,
    },

    props: {
      spotConfig: {
        type: Object,
        required: false,
      },
      visited: {
        type: Boolean,
        required: false,
      },
    },

    computed: {
      label() {
        return this.spotConfig?.label || `default`;
      },
    },
  };
</script>

<style lang="scss">
  .label-hotspot {
    width: 200px;
    padding: 0.3em;
    font-size: 16px;
    color: #ff7200;
    background-color: rgba(255, 114, 0, 0.75);
    border-radius: 200px;
    font-weight: 500;
    z-index: 1;
    transform: translate(-50%, 75%) !important;

    .label {
      border-radius: 200px;
      padding: 0.4em 1.5em;
      background-color: #ffffff;
    }

    &.visited {
      color: #6a6a6a;
      background-color: rgba(205, 209, 210, 0.73);
    }
  }
</style>
