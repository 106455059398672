export default [
  {
    name: `splash_screen`,
    images: {
      background: "#F0EDE9",
      logo: {
        img: require(`@/assets/logos/logo_green.svg`),
        alt: "Ulé",
      },
    },
  },
];
