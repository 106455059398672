<template>
  <div
    class="reality-loading-screen light-mode"
    :class="{
      visible,
      mobile: $breakpoints.isMobile,
    }"
  >
    <video
      v-if="config.backgroundvideo"
      autoplay
      loop
      muted
      class="background-video"
      playsinline
    >
      <source :src="config.backgroundvideo" type="video/mp4" />
    </video>
    <div :class="{ frontbackgroundvideo: config.backgroundvideo }">
      <a href="/" class="noclick">
        <img
          class="logo"
          :src="config.images.logo.img"
          :alt="config.images.logo.alt"
      /></a>
      <div class="loading-screen-content">
        <h3 class="text-uppercase font-FoundersGrotesk">
          {{ config.welcomeText }}
        </h3>
        <h2 class="font-SaolText" v-html="config.title" />
        <button
          class="start font-FoundersGrotesk"
          v-if="loadingComplete"
          @click="$emit('endloading')"
        >
          {{ config.start }}
        </button>
        <div v-else class="loading-bar-wrapper">
          <div class="loading-bar-container">
            <div class="loading-bar" :style="`width: ${progress}%;`" />
          </div>
          <div class="loading-progress">{{ progressLabel }}%</div>
          <div class="loading-text">{{ config.loadingText }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      visible: {
        type: Boolean,
        required: false,
        default: () => false,
      },
      config: {
        type: Object,
        required: true,
      },
      progress: {
        type: Number,
        required: false,
        default: () => 0,
      },
    },
    data: () => ({
      loadingComplete: false,
    }),

    computed: {
      progressLabel() {
        return Math.ceil(this.progress);
      },
    },
    watch: {
      progressLabel(progressLabel) {
        if (progressLabel == 100) {
          setTimeout(() => {
            this.loadingComplete = true;
          }, 1000);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @use "../assets/css/theme";

  $loading-bar-height: 7px;
  $loading-bar-border-radius: 14px;

  @mixin animate($animation, $duration, $method, $times) {
    animation: $animation $duration $method $times;
  }

  @mixin keyframes($name) {
    @keyframes #{$name} {
      @content;
    }
  }

  .reality-loading-screen {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0;
    pointer-events: none;

    transition: opacity 0.5s ease-in-out;

    &.visible {
      opacity: 1;
      pointer-events: all;
    }

    .loading-screen-content {
      width: 100%;
      height: 100%;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h2 {
        font-weight: 100;
        margin: 100px 0;
        font-size: 160px;
      }
      h3 {
        font-size: 20px;
        font-weight: 200;
      }

      .description {
        width: 35%;
        font-weight: 200;
      }

      button.start {
        color: theme.$darkgreen;
        border: 1px solid theme.$darkgreen;
        border-radius: 3em;
        padding: 13px 22px;
        font-size: 20px;
        text-transform: uppercase;
        margin-top: 33px;
        font-weight: 200;
        margin-bottom: 35px;
        transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
        &:hover {
          background-color: theme.$darkgreen;
          color: #ffffff;
        }
      }

      .reality-logo-typo {
        width: 58px;
      }

      .reality-logo {
        width: calc(50% * 1 / 3);
      }

      .reality-logo-typo,
      .reality-logo {
        margin: 1em 0;
        margin-bottom: 43px;
      }

      .loading-bar-wrapper {
        width: 100%;
        max-width: 500px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: theme.$darkgreen;
        font-size: 12px;

        .loading-bar-container {
          margin-top: 6vh;
          position: relative;
          width: 100%;
          height: $loading-bar-height;
          border-radius: $loading-bar-border-radius;
          background-color: hsl(0, 0%, 92%);

          .loading-bar {
            position: absolute;
            top: 0;
            left: 0;
            height: $loading-bar-height;
            border-radius: $loading-bar-border-radius;
            background-color: theme.$darkgreen;
            transition: width 0.1s ease-in-out;
          }
        }

        .loading-progress {
          margin: 1em 0;
        }

        .loading-text {
          @include keyframes(blink) {
            0% {
              opacity: 0;
            }
            50% {
              opacity: 1;
            }
            100% {
              opacity: 0;
            }
          }
          @include animate(blink, 1.5s, ease, infinite);
        }
      }
    }

    &.mobile {
      .loading-screen-content {
        width: 100%;

        h2 {
          font-size: 3.5rem;
          margin: 20px 0 20px;
          font-weight: 300;
          span {
            font-weight: 200;
          }
        }

        h3 {
          font-size: 15px;
          width: 50%;
        }

        .description {
          width: 100%;
        }

        .reality-logo-typo {
          width: 10vw;
          margin: 6vh 0px 20px;
        }

        .reality-logo {
          width: calc(70% * 1 / 3);
        }

        .loading-bar-wrapper {
          width: 60%;
        }
      }
    }
  }
</style>
