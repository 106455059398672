<template>
  <div
    class="intro-video dark-mode"
    :class="{
      visible,
      mobile: $breakpoints.isMobile,
    }"
    @click="close"
  >
    <a href="/" class="noclick">
      <img class="logo" :src="video?.logo?.img" :alt="video?.logo?.alt"
    /></a>
    <div class="video-wrapper" @click.stop="noop">
      <video
        :src="video?.src"
        ref="video"
        @ended="videoEnded"
        playsinline
        webkit-playsinline
        v-if="hasVideo && isVideoVisible"
      >
        <!--  <track
          kind="subtitles"
          :src="video?.subtitle"
          default
          srclang="fr"
          label="French"
        />-->
      </video>
    </div>
    <custom-button
      v-if="isVideoVisible"
      :class="`${
        video?.align ? video?.align + '-align' : ''
      } close-button font-FoundersGrotesk`"
      @close="close"
      :text="video?.exitButton"
      :duration="duration"
    />
  </div>
</template>

<script>
  import CustomButton from "@/components/CustomButton";
  export default {
    components: {
      CustomButton,
    },

    props: {
      visible: {
        type: Boolean,
        required: false,
        default: () => false,
      },
      video: {
        type: Object,
      },
    },

    data: () => ({
      instance: null,
      isVideoVisible: false,
      duration: null,
    }),

    computed: {
      /*     duration(){
        if (this.visible) {
        return this.$refs[`video`]?.duration ||  null
        }
        return null
      },*/
      hasVideo() {
        return this.video && this.video.src;
      },
      canSkip() {
        return true;
      },
    },

    methods: {
      noop() {},
      close() {
        this.$emit(`close`);
        setTimeout(() => {
          this.isVideoVisible = false;
        }, 500);
      },
      videoEnded() {
        setTimeout(() => this.close(), 100);
      },
    },

    mounted() {
      this.isVideoVisible = true;
    },

    watch: {
      video() {
        // If video change, make isVideoVisible true
        this.isVideoVisible = true;
      },
      visible(visible) {
        if (visible) {
          setTimeout(() => {
            if (!visible) {
              this.isVideoVisible = false;
            }
            this.$refs[`video`].currentTime = 0;
            this.duration = this.$refs[`video`].duration;
            this.$refs[`video`].play();
          }, 500);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @use "../assets/css/theme";
  .intro-video {
    position: fixed;
    z-index: 11;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #000000;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    //   transition: opacity 0.1s ease-in-out;
    opacity: 0;
    pointer-events: none;
    &.visible {
      opacity: 1;
      pointer-events: all;
      .video-wrapper {
        opacity: 1;
      }
    }

    .video-wrapper {
      transition: opacity 2s ease-in-out;
      opacity: 0;
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;

      video {
        width: 100vw;
        height: 100vh;
        object-fit: contain;
        background: black;
        &::cue {
          line-height: 50px;
          // Style pour les sous titre
        }
      }
    }
    .close-button {
      position: fixed;
      bottom: 10%;
      left: 50%;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      font-size: 13px;
      padding: 1.15em 2.5em;
      border-radius: 3em;
      background-color: transparent;
      color: #ffffff;
      font-weight: 200;
      border: 1px solid #ffffff;
      transform: translate(-50%) !important;

      &.right-align {
        right: 60px;
        left: auto;
        transform: translate(0) !important;
      }
      &:hover {
        color: #ffffff;
      }
      img {
        height: 50px;
      }
    }
    &.mobile {
      .close-button {
        &.right-align {
          left: 50%;
          right: auto;
          transform: translate(-50%) !important;
        }
      }
    }
  }
</style>
