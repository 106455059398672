import { render, staticRenderFns } from "./HotspotUi.vue?vue&type=template&id=32a51635&"
import script from "./HotspotUi.vue?vue&type=script&lang=js&"
export * from "./HotspotUi.vue?vue&type=script&lang=js&"
import style0 from "./HotspotUi.vue?vue&type=style&index=0&id=32a51635&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports