export default [
  {
    name: `video_intro`,
    logo: {
      img: require(`@/assets/logos/logo_green.svg`),
      alt: "Ulé",
    },
  },
  {
    name: `nurserie`,
    logo: {
      img: require(`@/assets/logos/logo_green.svg`),
      alt: "Ulé",
    },
  },
  {
    name: `production_2`,
    logo: {
      img: require(`@/assets/logos/logo_green.svg`),
      alt: "Ulé",
    },
  },
  {
    name: `water_unit`,
    logo: {
      img: require(`@/assets/logos/logo_green.svg`),
      alt: "Ulé",
    },
  },
  {
    name: `showroom`,
    logo: {
      img: require(`@/assets/logos/logo_green.svg`),
      alt: "Ulé",
    },
  },
];
