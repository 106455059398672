export default [
  {
    name: `global`,
    sphere: require(`@/assets/glbs/sphere.glb`),
    arrow0: require(`@/assets/texs/arrow_00.png`),
    arrow1: require(`@/assets/texs/arrow_01.png`),
    arrow2: require(`@/assets/texs/arrow_02.png`),
    arrow3: require(`@/assets/texs/arrow_03.png`),
    arrowHighlight: require(`@/assets/texs/arrow_0H.png`),
  },
  {
    name: `couloir`,
    textureType: `image`,
    tex: require(`@/assets/360/couloir.jpg`),
    initialCamera: {
      lon: 158,
      azi: 5,
    },
    hotspots: {
      spots2D: [
        {
          name: "scene_change_nurserie",
          type: `basic-spot`,
          category: `link`,
          action: {
            type: `change-scene`,
            args: {
              scene: `nurserie`,
            },
          },
          longitude: -141,
          azimuth: 0,
        },
        {
          name: "scene_change_production_1",
          type: `basic-spot`,
          category: `link`,
          action: {
            type: `change-scene`,
            args: {
              scene: `production_1`,
            },
          },
          longitude: 134,
          azimuth: 0,
        },
        {
          name: "scene_change_water_unit",
          type: `basic-spot`,
          category: `link`,
          action: {
            type: `change-scene`,
            args: {
              scene: `water_unit`,
            },
          },
          longitude: 149,
          azimuth: -17,
        },
        {
          name: "scene_change_showroom",
          type: `basic-spot`,
          category: `link`,
          action: {
            type: `change-scene`,
            args: {
              scene: `showroom`,
            },
          },
          longitude: -180,
          azimuth: -2,
        },
      ],
      spots3D: [
        /* {
          name: "scene_change_nurserie",
          action: {
            type: `change-scene`,
            args: {
              scene: `nurserie`,
            },
          },
          image: "",
          size: 2.72,
          longitude: -178.5,
          azimuth: -18.5,
          inclinationX: 63,
          inclinationY: 0,
          direction: -1,
          clickShape: "circle",
          clickScaleX: 0.75,
          clickScaleY: 0.75,
          clickOffsetX: 0,
          clickOffsetY: 0,
        },*/
      ],
    },
  },
  {
    name: `nurserie`,
    textureType: `video`,
    video: require(`@/assets/videos/intro.mp4`),
    tex: require(`@/assets/360/nurserie.jpg`),
    videoTex: require(`@/assets/360/nurserie.mp4`),
    //tex: require(`@/assets/360/nurserie.jpg`),
    initialCamera: {
      lon: 180,
      azi: 5,
    },
    hotspots: {
      spots2D: [
        {
          name: `pepiniere1`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `pepiniere1`,
            },
          },
          longitude: 160,
          azimuth: -20,
        },
        {
          name: `pepiniere2`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `pepiniere2`,
            },
          },
          longitude: 141,
          azimuth: -15,
        },
        {
          name: `pepiniere3`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `pepiniere3`,
            },
          },
          longitude: -154,
          azimuth: 2,
        },
        {
          name: `pepiniere4`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `pepiniere4`,
            },
          },
          longitude: 159,
          azimuth: 23,
        },
        {
          name: `pepiniere5`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `pepiniere5`,
            },
          },
          longitude: -140,
          azimuth: -28,
        },
        {
          name: "scene_change_production_2",
          type: `basic-spot`,
          category: `link`,
          action: {
            type: `change-scene`,
            args: {
              scene: `production_2`,
            },
          },
          longitude: -175,
          azimuth: 15,
        },
      ],
      spots3D: [],
    },
  },
  {
    name: `production_1`,
    textureType: `video`,
    tex: require(`@/assets/360/production_1.jpg`),
    videoTex: require(`@/assets/360/production_1.mp4`),
    initialCamera: {
      lon: -178,
      azi: -10,
    },
    hotspots: {
      spots2D: [
        {
          name: `prod_hotspot_5`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `prod_dialog_5`,
            },
          },
          longitude: 151,
          azimuth: -7,
        },
        {
          name: `prod_hotspot_7`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `prod_dialog_7`,
            },
          },
          longitude: -117,
          azimuth: -4,
        },
        {
          name: `prod_hotspot_8`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `prod_dialog_8`,
            },
          },
          longitude: -144,
          azimuth: -2,
        },
        {
          name: "scene_change_water_unit",
          type: `basic-spot`,
          category: `link`,
          action: {
            type: `change-scene`,
            args: {
              scene: `water_unit`,
            },
          },
          longitude: -178,
          azimuth: 11,
        },
        {
          name: "scene_change_nurserie",
          type: `basic-spot`,
          category: `link`,
          action: {
            type: `change-scene`,
            args: {
              scene: `nurserie`,
            },
          },
          longitude: 4,
          azimuth: 20,
        },
        {
          name: "scene_change_production_2",
          type: `basic-spot`,
          category: `link`,
          action: {
            type: `change-scene`,
            args: {
              scene: `production_2`,
            },
          },
          longitude: 4,
          azimuth: -3,
        },
      ],
      spots3D: [
        /*{
          name: "scene_change_vendome_1",
          action: {
            type: `change-scene`,
            args: {
              scene: `place_vendome_1`,
            },
          },
          image: "arrow_00",
          size: 2.72,
          longitude: -178.5,
          azimuth: -18.5,
          inclinationX: 63,
          inclinationY: 0,
          direction: -1,
          clickShape: "circle",
          clickScaleX: 0.75,
          clickScaleY: 0.75,
          clickOffsetX: 0,
          clickOffsetY: 0,
        },*/
      ],
    },
  },
  {
    name: `production_2`,
    textureType: `video`,
    videoTex: require(`@/assets/360/production_2.mp4`),
    tex: require(`@/assets/360/production_2.jpg`),
    initialCamera: {
      lon: 180,
      azi: -10,
    },
    hotspots: {
      spots2D: [
        {
          name: `prod_hotspot_1`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `prod_dialog_1`,
            },
          },
          longitude: -131,
          azimuth: -8,
        },
        {
          name: `prod_hotspot_2`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `prod_dialog_2`,
            },
          },
          longitude: -84,
          azimuth: 5,
        },
        {
          name: `prod_hotspot_3`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `prod_dialog_3`,
            },
          },
          longitude: -115,
          azimuth: -17,
        },
        {
          name: `prod_hotspot_4`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `prod_dialog_4`,
            },
          },
          longitude: 143,
          azimuth: -7,
        },
        {
          name: `prod_hotspot_6`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `prod_dialog_6`,
            },
          },
          longitude: 108,
          azimuth: 1,
        },
        {
          name: "scene_change_nurserie",
          type: `basic-spot`,
          category: `link`,
          action: {
            type: `change-scene`,
            args: {
              scene: `nurserie`,
            },
          },
          longitude: 0,
          azimuth: -4,
        },
        {
          name: "scene_change_production_1",
          type: `basic-spot`,
          category: `link`,
          action: {
            type: `change-scene`,
            args: {
              scene: `production_1`,
            },
          },
          longitude: -179,
          azimuth: 17,
        },
      ],
      spots3D: [],
    },
  },
  {
    name: `water_unit`,
    textureType: `video`,
    video: require(`@/assets/videos/water_unit.mp4`),
    videoTex: require(`@/assets/360/water_unit.mp4`),
    tex: require(`@/assets/360/water_unit.jpg`),
    initialCamera: {
      lon: 47,
      azi: -10,
    },
    hotspots: {
      spots2D: [
        {
          name: `water_unit1`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `water_dialog_1`,
            },
          },
          longitude: 30,
          azimuth: -5,
        },
        {
          name: `water_unit2`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `water_dialog_2`,
            },
          },
          longitude: 15,
          azimuth: 8,
        },
        {
          name: `water_unit3`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `water_dialog_3`,
            },
          },
          longitude: 44,
          azimuth: -31,
        },
        {
          name: "scene_change_production_2",
          type: `basic-spot`,
          category: `link`,
          action: {
            type: `change-scene`,
            args: {
              scene: `production_2`,
            },
          },
          longitude: -27,
          azimuth: -5,
        },
        {
          name: "scene_change_nurserie",
          type: `basic-spot`,
          category: `link`,
          action: {
            type: `change-scene`,
            args: {
              scene: `nurserie`,
            },
          },
          longitude: -87,
          azimuth: -2,
        },
      ],
      spots3D: [],
    },
  },
  {
    name: `showroom`,
    textureType: `image`,
    video: require(`@/assets/videos/showroom.mp4`),
    tex: require(`@/assets/360/showroom_1.webp`),
    initialCamera: {
      lon: -160,
      azi: -7,
    },
  },
  {
    name: `showroom_2`,
    textureType: `image`,
    tex: require(`@/assets/360/showroom_2.webp`),
    initialCamera: {
      lon: 15,
      azi: -10,
    },
    hotspots: {
      spots2D: [
        {
          name: `showroom_6`,
          type: `basic-spot`,
          category: `basic`,
          action: {
            type: `dialog`,
            args: {
              dialog: `showroom_dialog_6`,
            },
          },
          longitude: 166,
          azimuth: -27,
        },
        {
          name: "scene_change_showroom",
          type: `basic-spot`,
          category: `link`,
          action: {
            type: `change-scene`,
            args: {
              scene: `showroom`,
            },
          },
          longitude: -165,
          azimuth: -17,
        },
      ],
      spots3D: [],
    },
  },
];
