export default {
  name: `map`,
  elements: [
    {
      name: `nurserie`,
      link: `nurserie`,
      background: require(`@/assets/map/mobile/nurserie.png`),
      position: `50% 50%`,
    },
    {
      name: `production_2`,
      link: `production_2`,
      background: require(`@/assets/map/mobile/production.png`),
      position: `50% 50%`,
    },
    {
      name: `water_unit`,
      link: `water_unit`,
      background: require(`@/assets/map/mobile/water_unit.png`),
      position: `50% 50%`,
    },
    {
      name: `showroom`,
      link: `showroom`,
      background: require(`@/assets/map/mobile/showroom.png`),
      position: `50% 50%`,
    },
  ],
};
