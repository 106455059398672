export default [
  {
    key: `fr_FR`,
    shortLabel: `FR`,
    label: `Français`,
    isDefault: false,
    startCTA: `COMMENCER`,
  },
  {
    key: `en_EN`,
    shortLabel: `EN`,
    label: `English`,
    isDefault: true,
    startCTA: `START`,
  },
];
