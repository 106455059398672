export default {
  name: `menu`,
  entries: [
    {
      name: "nurserie",
      action: "change-scene",
      scene: "nurserie",
      image: require(`@/assets/menu/nurserie.webp`),
    },
    {
      name: "production_2",
      action: "change-scene",
      scene: "production_2",
      image: require(`@/assets/menu/prod.webp`),
    },
    {
      name: "water_unit",
      action: "change-scene",
      scene: "water_unit",
      image: require(`@/assets/menu/water.webp`),
    },
    {
      name: "showroom",
      action: "change-scene",
      scene: "showroom",
      image: require(`@/assets/menu/showroom.webp`),
    },
    /*   {
      name: `toggle_fullscreen`,
      icon: `<svg width="97" viewBox="0 0 97 73" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M26.9733 5.18717H5.70588V27.492C5.70588 28.9244 4.5447 30.0856 3.1123 30.0856C1.6799 30.0856 0.518717 28.9244 0.518717 27.492V4.66845C0.518717 2.09013 2.60885 0 5.18717 0H26.9733C28.4057 0 29.5668 1.16119 29.5668 2.59358C29.5668 4.02598 28.4057 5.18717 26.9733 5.18717Z" fill="white"/><path d="M91.8128 26.9733V5.70588H69.508C68.0756 5.70588 66.9144 4.5447 66.9144 3.1123C66.9144 1.6799 68.0756 0.518717 69.508 0.518717H92.3316C94.9099 0.518717 97 2.60885 97 5.18717V26.9733C97 28.4057 95.8388 29.5668 94.4064 29.5668C92.974 29.5668 91.8128 28.4057 91.8128 26.9733Z" fill="white"/><path d="M5.18717 66.9144V45.6471C5.18717 44.2147 4.02598 43.0535 2.59358 43.0535C1.16119 43.0535 0 44.2147 0 45.6471V67.4332C0 70.0115 2.09013 72.1016 4.66845 72.1016H27.492C28.9244 72.1016 30.0856 70.9404 30.0856 69.508C30.0856 68.0756 28.9244 66.9144 27.492 66.9144H5.18717Z" fill="white"/><path d="M70.0267 67.4332H91.2941V45.1283C91.2941 43.6959 92.4553 42.5348 93.8877 42.5348C95.3201 42.5348 96.4813 43.6959 96.4813 45.1283V67.9519C96.4813 70.5302 94.3911 72.6203 91.8128 72.6203H70.0267C68.5943 72.6203 67.4332 71.4591 67.4332 70.0267C67.4332 68.5943 68.5943 67.4332 70.0267 67.4332Z" fill="white"/></svg>`,
      action: `toggle-fullscreen`,
    },*/
  ],
};
