<template>
  <div
    class="background"
    :class="{
      hidden: !visible,
    }"
    style="z-index: 8"
  >
    <div
      class="reality-dialog"
      :class="{
        visible,
        mobile: $breakpoints.isMobile,
      }"
    >
      <div v-if="dialog.img || dialog.video" class="image-wrapper visible">
        <img
          v-if="dialog.img"
          :src="dialog.img"
          :alt="`${dialog.name}-image`"
        />
        <video
          v-if="dialog.video && dialog.video.length"
          playsinline
          autoplay
          loop
        >
          <source :src="dialog.video" type="video/mp4" />
        </video>
      </div>
      <template v-if="hasDialog">
        <div class="content-wrapper">
          <div class="dialog-title">{{ dialog.title }}</div>
          <div class="dialog-content">
            <p
              class="dialog-paragraph font-FoundersGrotesk"
              v-for="(paragraph, idx) in dialog.content"
              :key="`${dialog.name}-paragraph-${idx + 1}`"
              v-html="paragraph"
            />
          </div>
          <a
            class="button dialog-link text-uppercase font-FoundersGrotesk"
            target="_blank"
            v-if="dialog.link"
            v-html="dialog.link.text"
            :href="dialog.link.link"
          />
        </div>
        <button class="close-button" @click.stop="close">
          <img src="@/assets/icons/cross_thin.svg" />
        </button>
      </template>
      <template v-if="hasSound">
        <audio v-if="!muted" autoplay>
          <source :src="dialog.sound" type="audio/mpeg" />
        </audio>
      </template>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      visible: {
        type: Boolean,
        required: false,
        default: () => false,
      },
      muted: {
        type: Boolean,
        required: false,
        default: () => false,
      },
      dialog: {
        type: Object,
        required: true,
      },
      name: {
        type: String,
        required: true,
      },
    },

    computed: {
      hasDialog() {
        const keys = Object.keys(this.dialog);

        if (
          !keys.includes("name") ||
          !keys.includes("title") ||
          !keys.includes("content")
        ) {
          return false;
        }
        return true;
      },
      hasSound() {
        const keys = Object.keys(this.dialog);
        return !keys.includes("sound") ? false : true;
      },
    },

    methods: {
      close() {
        this.$emit(`close`);
      },
      openVideo() {
        if (this.dialog.video && this.dialog.video.length) {
          this.$emit(`open-video`, { src: this.dialog.video, hasSound: true });
        }
      },
    },
  };
</script>

<style lang="scss">
  @use "../assets/css/theme";

  .reality-dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    text-align: left;
    transition: opacity 0.5s ease-in-out;

    &.visible {
      opacity: 1;
      pointer-events: all;
    }

    .image-wrapper {
      width: 100%;
      height: 40%;
      opacity: 0;
      transition: opacity 0.5s ease-in;

      &.visible {
        opacity: 1;
      }

      img {
        width: calc(100% - 72px);
        height: calc(100% - 84px);
        object-fit: cover;
        object-position: center;
        margin: 42px 36px;
        border: 1px solid theme.$darkgreen;
      }
      video {
        //     width: calc(100% - 72px);
        //     margin: 0 36px;
        //    position: relative;
        //    top: calc(50%);
        //   transform: translateY(-50%);
        //    height: 100%;

        width: calc(100% - 72px);
        height: calc(100% - 84px);
        object-fit: cover;
        object-position: center;
        margin: 42px 36px;
        border: 1px solid #456d45;
      }
    }

    .content-wrapper {
      width: 100%;
      padding: 23px 3.5em 5% 0;
      flex-grow: 1;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      font-size: 14px;
      overflow-y: auto;

      overflow-x: hidden;

      .dialog-title {
        color: theme.$darkgreen;
        font-size: 3em;
        text-align: left;
        font-weight: 200;
        margin-bottom: 0.3em;
      }

      .dialog-paragraph {
        font-size: 15px;
        line-height: 25px;
        padding-right: 25px;
        padding-bottom: 10px;
        text-align: left;
      }

      .dialog-content {
        overflow-y: scroll;
        scrollbar-width: thin;
        scrollbar-color: #d3d3d3 transparent;
        width: 100%;
        height: 100%;
        text-align: center;
        position: relative;

        &::-webkit-scrollbar {
          width: 3px;
        }

        &::-webkit-scrollbar-track {
          background: transparent;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #d3d3d3;
          border-radius: 20px;
          border: 3px solid #d3d3d3;
        }

        &::-webkit-scrollbar-track-piece:start {
          background-color: transparent;
          // margin-top: 20px;
        }

        &::-webkit-scrollbar-track-piece:end {
          background-color: transparent;
          // margin-bottom: 20px;
        }
      }
      .dialog-link {
        margin-top: 20px;
        display: inline-block;
        margin-bottom: 5px;
        margin-left: auto;
        margin-right: auto;
      }
      .dialog-video-btn {
        font-size: 12px;
        margin-top: auto;
        padding: 1em 2em;
        color: #ff7200;
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        border: 1px solid #ff7200;
        border-radius: 4em;
        background-color: #ffffff;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

        &:hover {
          color: #ffffff;
          background-color: #ff7200;
        }

        &:focus {
          outline: none;
        }
      }
    }

    &:not(.mobile) {
      width: 900px;
      max-width: 80%;
      height: 58%;
      flex-direction: row;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      & > div {
        width: 50%;
      }

      .image-wrapper {
        height: 100%;
      }

      .content-wrapper {
        height: 100%;
        font-size: 16px;

        .dialog-link {
          margin-top: auto;
        }
      }
    }

    &.mobile {
      z-index: 20;
      width: calc(100% - 2em);
      margin: 3em 1em;
      height: calc(100% - 6em);
      .close-button {
        padding: 10px;
        img {
          width: 85%;
        }
      }
      .image-wrapper {
        height: 38%;
        min-height: 34vh;
        img,
        video {
          margin: 42px 21px;
          width: calc(100% - 42px);
          height: calc(100% - 55px);
        }
      }
      .content-wrapper {
        padding-top: 0;
        padding-left: 1.5em;
        padding-right: 1.5em;
        .dialog-title {
          font-size: 9.5vw;
        }
        .dialog-paragraph {
          padding-right: 0px;
          padding-bottom: 10px;
        }
      }
    }

    .close-button {
      position: absolute;
      top: 0px;
      right: 0px;
      padding: 20px;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      color: #ff7200;
      border: none;
      background: none;
      line-height: 17px;
      img {
        width: 100%;
        transition: all 0.2s ease-in-out;
      }
      &:hover {
        color: darken(#ff7200, 20);
        img {
          width: 90%;
        }
      }
    }

    &.video {
      height: 45%;
      .image-wrapper {
        width: 60%;
        padding: 10px;
        video {
          position: relative;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .content-wrapper {
        width: 40%;
        font-size: 13px;
      }
    }
  }
</style>
